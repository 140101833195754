import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  Output,
  SimpleChanges
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import {
  IFormError,
  markAllFormControlsAsTouched,
  setFormErrors
} from "@bubbleup/ngx-form-validation";
import { Platform } from "@ionic/angular";
import { IPaymentFormData } from "../../../interfaces/payment-form";
import { combineLatest } from "rxjs";
import * as moment from "moment";
import { startWith } from "rxjs/operators";
import { formatCurrency } from "@angular/common";

@Component({
  selector: "app-payment-form",
  templateUrl: "./payment-form.component.html",
  styleUrls: ["./payment-form.component.scss"]
})
export class PaymentFormComponent implements OnChanges {
  group: FormGroup;

  @Input()
  inProcess: boolean = false;

  @Input() serverErrors: IFormError[] = [];

  // @todo: ildar. temporary disable recaptcha for ios
  isIos: boolean;

  @Input() recaptchaSiteKey: string = "";

  recaptchaSize: string;

  @Output() submit: EventEmitter<IPaymentFormData> = new EventEmitter<
    IPaymentFormData
  >();

  private gpraMembershipDuesAmount: number = 55;
  private gpraSecurityDuesAmount: number = 300;

  constructor(
    private formBuilder: FormBuilder,
    private platform: Platform,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.isIos = this.platform.is("ios");

    this.group = this.formBuilder.group({
      gpraMembershipDues: [""],
      gpraSecurityDues: [""],
      donateBeautification: [""],
      donateEquipment: [""],
      amount: ["", Validators.required],
      creditCardNumber: ["", Validators.required],
      creditCardExpirationMonth: [""],
      creditCardExpirationYear: [""],
      creditCardExpiration: ["", Validators.required],
      creditCardCvv: ["", Validators.required],

      billingFirstName: ["", Validators.required],
      billingLastName: ["", Validators.required],
      billingEmail: ["", Validators.required],
      billingPhone: ["", Validators.required],
      billingAddress: ["", Validators.required],
      billingAddress2: ["", Validators.required],
      billingCity: ["", Validators.required],
      billingState: ["", Validators.required],
      billingZip: ["", Validators.required],
      billingCountry: ["United States", Validators.required],

      mailingEqualsBilling: [false],

      mailingFirstName: ["", Validators.required],
      mailingLastName: ["", Validators.required],
      mailingAddress: ["", Validators.required],
      mailingAddress2: ["", Validators.required],
      mailingCity: ["", Validators.required],
      mailingState: ["", Validators.required],
      mailingZip: ["", Validators.required],
      mailingCountry: ["United States", Validators.required]
    });

    if (!this.isIos) {
      this.group.setControl(
        "recaptcha",
        new FormControl("", Validators.required)
      );
    }

    this.recaptchaSize = this.platform.is("mobile") ? "compact" : "normal";

    const gpraMembershipDuesControl: FormControl = this.group.get(
      "gpraMembershipDues"
    ) as FormControl;
    const gpraSecurityDuesControl: FormControl = this.group.get(
      "gpraSecurityDues"
    ) as FormControl;

    const donateBeautificationControl: FormControl = this.group.get(
      "donateBeautification"
    ) as FormControl;
    const donateEquipmentControl: FormControl = this.group.get(
      "donateEquipment"
    ) as FormControl;

    const amountControl: FormControl = this.group.get("amount") as FormControl;

    const creditCardExpirationMonthControl: FormControl = this.group.get(
      "creditCardExpirationMonth"
    ) as FormControl;
    const creditCardExpirationYearControl: FormControl = this.group.get(
      "creditCardExpirationYear"
    ) as FormControl;
    const creditCardExpirationControl: FormControl = this.group.get(
      "creditCardExpiration"
    ) as FormControl;

    const billingFirstNameControl: FormControl = this.group.get(
      "billingFirstName"
    ) as FormControl;
    const billingLastNameControl: FormControl = this.group.get(
      "billingLastName"
    ) as FormControl;
    const billingAddressControl: FormControl = this.group.get(
      "billingAddress"
    ) as FormControl;
    const billingAddress2Control: FormControl = this.group.get(
      "billingAddress2"
    ) as FormControl;
    const billingCityControl: FormControl = this.group.get(
      "billingCity"
    ) as FormControl;
    const billingStateControl: FormControl = this.group.get(
      "billingState"
    ) as FormControl;
    const billingZipControl: FormControl = this.group.get(
      "billingZip"
    ) as FormControl;
    const billingCountryControl: FormControl = this.group.get(
      "billingCountry"
    ) as FormControl;
    const mailingEqualsBillingControl: FormControl = this.group.get(
      "mailingEqualsBilling"
    ) as FormControl;

    const mailingFirstNameControl: FormControl = this.group.get(
      "mailingFirstName"
    ) as FormControl;
    const mailingLastNameControl: FormControl = this.group.get(
      "mailingLastName"
    ) as FormControl;
    const mailingAddressControl: FormControl = this.group.get(
      "mailingAddress"
    ) as FormControl;
    const mailingAddress2Control: FormControl = this.group.get(
      "mailingAddress2"
    ) as FormControl;
    const mailingCityControl: FormControl = this.group.get(
      "mailingCity"
    ) as FormControl;
    const mailingStateControl: FormControl = this.group.get(
      "mailingState"
    ) as FormControl;
    const mailingZipControl: FormControl = this.group.get(
      "mailingZip"
    ) as FormControl;
    const mailingCountryControl: FormControl = this.group.get(
      "mailingCountry"
    ) as FormControl;

    combineLatest(
      gpraMembershipDuesControl.valueChanges.pipe(startWith(false)),
      gpraSecurityDuesControl.valueChanges.pipe(startWith(false)),
      donateBeautificationControl.valueChanges.pipe(startWith(null)),
      donateEquipmentControl.valueChanges.pipe(startWith(null))
    ).subscribe(
      ([
        gpraMembershipDues,
        gpraSecurityDues,
        donateBeautification,
        donateEquipment
      ]) => {
        let sum: number = 0;
        if (gpraMembershipDues) {
          sum += this.gpraMembershipDuesAmount;
        }
        if (gpraSecurityDues) {
          sum += this.gpraSecurityDuesAmount;
        }
        if (donateBeautification) {
          sum += donateBeautification;
        }
        if (donateEquipment) {
          sum += donateEquipment;
        }
        amountControl.setValue(formatCurrency(sum, this.locale, "$"));
      }
    );

    combineLatest(
      creditCardExpirationMonthControl.valueChanges,
      creditCardExpirationYearControl.valueChanges
    ).subscribe(([month, year]) => {
      let expirationDate = "";
      if (month && year) {
        expirationDate = moment(`${year}-${month}`).format("MM/YY");
      }
      creditCardExpirationControl.setValue(expirationDate);
    });

    combineLatest(
      billingFirstNameControl.valueChanges.pipe(
        startWith(billingFirstNameControl.value)
      ),
      billingLastNameControl.valueChanges.pipe(
        startWith(billingLastNameControl.value)
      ),
      billingAddressControl.valueChanges.pipe(
        startWith(billingAddressControl.value)
      ),
      billingAddress2Control.valueChanges.pipe(
        startWith(billingAddress2Control.value)
      ),
      billingCityControl.valueChanges.pipe(startWith(billingCityControl.value)),
      billingStateControl.valueChanges.pipe(
        startWith(billingStateControl.value)
      ),
      billingZipControl.valueChanges.pipe(startWith(billingZipControl.value)),
      billingCountryControl.valueChanges.pipe(
        startWith(billingCountryControl.value)
      ),
      mailingEqualsBillingControl.valueChanges.pipe(
        startWith(mailingEqualsBillingControl.value)
      )
    ).subscribe(
      ([
        billingFirstName,
        billingLastName,
        billingAddress,
        billingAddress2,
        billingCity,
        billingState,
        billingZip,
        billingCountry,
        mailingEqualsBilling
      ]) => {
        if (mailingEqualsBilling) {
          mailingFirstNameControl.disable();
          mailingLastNameControl.disable();
          mailingAddressControl.disable();
          mailingAddress2Control.disable();
          mailingCityControl.disable();
          mailingStateControl.disable();
          mailingZipControl.disable();
          mailingCountryControl.disable();

          mailingFirstNameControl.setValue(billingFirstName);
          mailingLastNameControl.setValue(billingLastName);
          mailingAddressControl.setValue(billingAddress);
          mailingAddress2Control.setValue(billingAddress2);
          mailingCityControl.setValue(billingCity);
          mailingStateControl.setValue(billingState);
          mailingZipControl.setValue(billingZip);
          mailingCountryControl.setValue(billingCountry);
        }
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.serverErrors) {
      const currentValue: IFormError[] = changes.serverErrors.currentValue;
      if (currentValue.length) {
        setFormErrors(this.group, currentValue);
      }
    }
  }

  validateAndSubmit() {
    if (this.inProcess) {
      return;
    }
    if (this.group.invalid) {
      return markAllFormControlsAsTouched(this.group);
    }

    const submitData: IPaymentFormData = {
      BillingForm: [
        {
          firstName: this.group.value.billingFirstName,
          lastName: this.group.value.billingLastName,
          email: this.group.value.billingEmail,
          phone: this.group.value.billingPhone,
          address: this.group.value.billingAddress,
          address2: this.group.value.billingAddress2,
          city: this.group.value.billingCity,
          state: this.group.value.billingState,
          zip: this.group.value.billingZip,
          country: this.group.value.billingCountry
        }
      ],
      BillingForm2: [
        {
          firstName: this.group.value.mailingFirstName,
          lastName: this.group.value.mailingLastName,
          address: this.group.value.mailingAddress,
          address2: this.group.value.mailingAddress2,
          city: this.group.value.mailingCity,
          state: this.group.value.mailingState,
          zip: this.group.value.mailingZip,
          country: this.group.value.mailingCountry
        }
      ],
      paymentForm: [
        {
          cardinfo: {
            "card-number": this.group.value.creditCardNumber,
            "card-expiration": this.group.value.creditCardExpiration,
            "security-code": this.group.value.creditCardCvv
          },
          payment: {
            amount: this.group.value.amount
          },
          lineItems: []
        }
      ]
    };

    if (this.group.value.gpraMembershipDues) {
      submitData.paymentForm[0].lineItems.push({
        name: "gpraMembershipDues",
        amount: String(this.gpraMembershipDuesAmount),
        quantity: "1"
      });
    }
    if (this.group.value.gpraSecurityDues) {
      submitData.paymentForm[0].lineItems.push({
        name: "gpraSecurityDues",
        amount: String(this.gpraSecurityDuesAmount),
        quantity: "1"
      });
    }
    if (this.group.value.donateBeautification) {
      submitData.paymentForm[0].lineItems.push({
        name: "donateBeautification",
        amount: String(this.group.value.donateBeautification),
        quantity: "1"
      });
    }
    if (this.group.value.donateEquipment) {
      submitData.paymentForm[0].lineItems.push({
        name: "donateEquipment",
        amount: String(this.group.value.donateEquipment),
        quantity: "1"
      });
    }

    this.submit.emit(submitData);
  }
}
