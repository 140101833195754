import { Component, Input, OnInit } from "@angular/core";
import { IEventItem } from "../../../interfaces/event";

@Component({
  selector: "app-event-details-page-event-block",
  templateUrl: "./event-details-page-event-block.component.html",
  styleUrls: ["./event-details-page-event-block.component.scss"]
})
export class EventDetailsPageEventBlockComponent implements OnInit {
  @Input() item: IEventItem | undefined;

  constructor() {}

  ngOnInit() {}
}
