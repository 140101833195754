import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { IUserItem } from "../interfaces/user";
@Injectable({
  providedIn: "root"
})
export class AuthService {
  constructor() {}

  initCurrentUser(): Observable<IUserItem | null> {
    return of(null);
  }
}
