import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-welcome-page-slider-container",
  templateUrl: "./welcome-page-slider-container.component.html",
  styleUrls: ["./welcome-page-slider-container.component.scss"]
})
export class WelcomePageSliderContainerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
