import { Component, Input, OnInit } from "@angular/core";
import { INewsItem } from "../../../interfaces/news";

@Component({
  selector: "app-news-page-gpra-newsletters-block",
  templateUrl: "./news-page-gpra-newsletters-block.component.html",
  styleUrls: ["./news-page-gpra-newsletters-block.component.scss"]
})
export class NewsPageGpraNewslettersBlockComponent {
  @Input() items: INewsItem[] = [];
  @Input() inProcess: boolean = true;
  @Input() hasErrors: boolean = false;

  constructor() {}

  trackByNews(index: number, news: INewsItem): string {
    return news.path;
  }
}
