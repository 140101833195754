import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  getFormErrorsFrom,
  IFormError,
  IGraphqlErrorResponse
} from "@bubbleup/ngx-form-validation";
import { Observable, Subject } from "rxjs";
import { Router } from "@angular/router";
import { NgRedux } from "@angular-redux/store";
import { IAppState } from "../../../redux/state/app-state.interface";
import { takeUntil } from "rxjs/operators";
import { IContactFormData } from "../../../interfaces/contact-form";
import { FeedbackService } from "../../../services/feedback.service";

@Component({
  selector: "app-contact-form-container",
  templateUrl: "./contact-form-container.component.html",
  styleUrls: ["./contact-form-container.component.scss"]
})
export class ContactFormContainerComponent implements OnDestroy {
  inProcess: boolean = false;
  formErrors: IFormError[] = [];

  recaptchaSiteKey: string = "";

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private redux: NgRedux<IAppState>,
    private feedbackService: FeedbackService
  ) {
    const recaptchaSiteKey$: Observable<string> = redux.select([
      "recaptchaSiteKey"
    ]);
    recaptchaSiteKey$
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => (this.recaptchaSiteKey = value));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  submitForm(data: IContactFormData) {
    this.inProcess = true;
    this.feedbackService.submit(data).subscribe(
      res => {
        this.inProcess = false;
        this.router.navigate(["/contact-thank-you"], {
          skipLocationChange: true
        });
      },
      (err: IGraphqlErrorResponse) => {
        this.inProcess = false;
        this.formErrors = getFormErrorsFrom(err);
      }
    );
  }
}
