import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import { flatMap, map } from "rxjs/operators";

const siteId: string = "5";

@Injectable({
  providedIn: "root"
})
export class SubmitContentService {
  constructor() {}

  submit(path: string, data: any): Observable<any> {
    const statement = `mutation submitContent($data: String!, $site: ID!, $path: String!){ 
      submitContent(contentInput: {
        site: $site
        path: $path
        data: $data
      }) {
        path
        data
      }
    }
    `;

    const gqlAPIServiceArguments: any = {
      site: siteId,
      data: JSON.stringify(data),
      path
    };

    // @ts-ignore
    return from(
      fetch("https://pwa.7.staging.bubbleup.com/api/v1/graph", {
        method: "POST",
        body: JSON.stringify({
          query: statement,
          variables: gqlAPIServiceArguments
        }), // Coordinate the body type with 'Content-Type'
        headers: new Headers({
          "Content-Type": "application/json",
          site: "5"
        })
      })
    ).pipe(
      flatMap(res => res.json()),
      map((response: any) => response.data.submitContent)
    );
  }
}
