import { Component, Input, OnInit } from "@angular/core";
import { IMediaItem } from "../../../interfaces/media";
import { Lightbox } from "ngx-lightbox";
import { getImageHdUrl, getImageThumbUrl } from "../../../utils/images";

@Component({
  selector: "app-photos-list",
  templateUrl: "./photos-list.component.html",
  styleUrls: ["./photos-list.component.scss"]
})
export class PhotosListComponent {
  @Input() items: IMediaItem[] = [];
  @Input() inProcess: boolean = true;
  @Input() hasErrors: boolean = false;

  constructor(private lightbox: Lightbox) {}

  trackByMediaItem(index: number, item: IMediaItem): string {
    return item.filePath;
  }

  openModal(index: number): void {
    this.lightbox.open(
      this.items.map(item => ({
        src: getImageHdUrl(item.filePath),
        caption: item.title,
        thumb: getImageThumbUrl(item.filePath)
      })),
      index
    );
  }
}
