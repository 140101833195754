// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from "../app/interfaces/environment";

export const environment: IEnvironment = {
  production: false,
  siteBaseUrl: "https://www.aerosmith.com",
  myDataBaseUrl: "https://mydata.bubbleup.com",
  cachedDataUrl:
    "https://o353wvcl1l.execute-api.us-east-1.amazonaws.com/dev?site=3",
  shopifyConfig: {
    domain: "grangersmith.myshopify.com",
    apiKey: "4b48f56a6d2878523c81171dfa12a0b9",
    pass: "4b48f56a6d2878523c81171dfa12a0b9",
    accessToken: "4b48f56a6d2878523c81171dfa12a0b9",
    gql: "4b48f56a6d2878523c81171dfa12a0b9",
    requestUrl:
      "https://6jaw5m8i14.execute-api.us-east-2.amazonaws.com/storefront"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
