import { handleActions } from "redux-actions";
import { IReduxAction } from "../../interfaces/redux-action";
import {
  GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS,
  GET_ALL_PAGES_CONTENT_LIST_SUCCESS
} from "../../constants/page-content.constants";
import { IPagesContentList } from "../../../interfaces/page-content";
import { IReduxEventsPageContent } from "../../interfaces/content/events-page-content";

const getEventIds = (payload: IPagesContentList): string[] => {
  const pathPrefix = "/events/events/";
  return payload.items
    .filter(item => item.path.startsWith(pathPrefix))
    .map(item => item.path);
};

const getClubGalleryIds = (payload: IPagesContentList): string[] => {
  const pathPrefix = "/news/clubs/";
  return payload.items
    .filter(item => item.path.startsWith(pathPrefix))
    .map(item => item.path);
};

const getAllPagesContentListFirstChunkSuccess = (
  state: IReduxEventsPageContent,
  { payload }: IReduxAction<IPagesContentList>
): IReduxEventsPageContent => {
  const eventIds: string[] = getEventIds(payload);
  const clubGalleryIds: string[] = getClubGalleryIds(payload);

  const inProcess: boolean = payload.nextToken ? true : false;

  return Object.assign({}, state, {
    events: {
      inProcess,
      hasErrors: false,
      items: eventIds
    },
    clubs: {
      inProcess,
      hasErrors: false,
      items: clubGalleryIds
    }
  });
};

const getAllPagesContentListSuccess = (
  state: IReduxEventsPageContent,
  { payload }: IReduxAction<IPagesContentList>
): IReduxEventsPageContent => {
  const eventIds: string[] = getEventIds(payload);
  const clubGalleryIds: string[] = getClubGalleryIds(payload);

  const inProcess: boolean = payload.nextToken ? true : false;

  return Object.assign({}, state, {
    events: {
      inProcess,
      hasErrors: false,
      items: [state.events.items, ...eventIds]
    },
    clubs: {
      inProcess,
      hasErrors: false,
      items: [state.clubs.items, ...clubGalleryIds]
    }
  });
};

export const eventsPageContent = handleActions(
  {
    [GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS]: getAllPagesContentListFirstChunkSuccess,
    [GET_ALL_PAGES_CONTENT_LIST_SUCCESS]: getAllPagesContentListSuccess
  },
  null
);
