import { Component, Input, OnInit } from "@angular/core";
import { INewsItem } from "../../../interfaces/news";
import { ModalController } from "@ionic/angular";
import { SingleClubModalComponent } from "../single-club-modal/single-club-modal.component";

@Component({
  selector: "app-events-page-clubs-block",
  templateUrl: "./events-page-clubs-block.component.html",
  styleUrls: ["./events-page-clubs-block.component.scss"]
})
export class EventsPageClubsBlockComponent {
  @Input() items: INewsItem[] = [];
  @Input() inProcess: boolean = true;
  @Input() hasErrors: boolean = false;

  constructor(private modalController: ModalController) {}

  trackByNews(index: number, item: INewsItem): string {
    return item.path;
  }

  async openModal(item: INewsItem): Promise<void> {
    const modal = await this.modalController.create({
      component: SingleClubModalComponent,
      componentProps: {
        item
      }
    });
    modal.present();
  }
}
