import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { INewsItem } from "../../../interfaces/news";

@Component({
  selector: "app-single-club-modal",
  templateUrl: "./single-club-modal.component.html",
  styleUrls: ["./single-club-modal.component.scss"]
})
export class SingleClubModalComponent {
  @Input() item: INewsItem | undefined;

  constructor(private modalCtrl: ModalController) {}

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
