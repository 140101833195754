import { IAppState } from "./app-state.interface";
import { IReduxListContent } from "../interfaces/content/list-content";

const getList = (): IReduxListContent => ({
  items: [],
  inProcess: true,
  hasErrors: false
});

export const initialAppState: IAppState = {
  cacheName: "groganspoint-7",
  recaptchaSiteKey: "6LdfCLAUAAAAAIdAnDb_mrCpj21Qz-0-un5qyHRT",
  isAuthenticated: false,
  userId: null,
  content: {
    newsPage: {
      announcements: getList(),
      newsletters: getList()
    },
    newsArchivePage: getList(),
    tourPage: getList(),
    galleriesPage: getList(),
    videosPage: getList(),
    welcomePage: {
      news: getList(),
      events: getList()
    },
    cart: {
      id: ""
    },
    eventsPage: {
      events: getList(),
      clubs: getList()
    },
    photosPage: {
      inProcess: true,
      hasErrors: false,
      galleryId: ""
    },
    executiveBoardPage: {
      inProcess: true,
      hasErrors: false,
      galleryId: ""
    },
    boardOfDirectorsPage: {
      inProcess: true,
      hasErrors: false,
      galleryId: ""
    }
  },
  entities: {
    news: {},
    concerts: {},
    galleries: {},
    medias: {},
    navigationMenus: {},
    simplePages: {},
    users: {},
    cart: {},
    events: {}
  }
};
