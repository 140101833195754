import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-events-page-content-blocks",
  templateUrl: "./events-page-content-blocks.component.html",
  styleUrls: ["./events-page-content-blocks.component.scss"]
})
export class EventsPageContentBlocksComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
