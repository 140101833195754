import { Component, OnDestroy } from "@angular/core";
import { combineLatest, Observable, Subject } from "rxjs";
import { IAppState } from "../../../redux/state/app-state.interface";
import { getSchemaEntitiesObservable } from "../../../utils/normalizr";
import { takeUntil, throttleTime } from "rxjs/operators";
import { NgRedux } from "@angular-redux/store";
import { IGalleryItem } from "../../../interfaces/gallery";
import { IMediaItem } from "../../../interfaces/media";
import { gallerySchema } from "../../../normalizr/schemas/gallery.schema";
import { IPartialReduxEntities } from "../../../redux/interfaces/entities";
import { denormalize } from "normalizr";
import { IReduxSingleGalleryContent } from "../../../redux/interfaces/content/single-gallery-content";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-photos-list-container",
  templateUrl: "./photos-list-container.component.html",
  styleUrls: ["./photos-list-container.component.scss"]
})
export class PhotosListContainerComponent implements OnDestroy {
  items: IMediaItem[] = [];
  inProcess: boolean = true;
  hasErrors: boolean = false;

  currentPage: number = 1;

  itemsPerPage: number = 8;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    redux: NgRedux<IAppState>,
    route: ActivatedRoute
  ) {
    const photosPageContent$: Observable<
      IReduxSingleGalleryContent
    > = redux.select(["content", "photosPage"]);
    const entities$: Observable<
      IPartialReduxEntities
    > = getSchemaEntitiesObservable(redux, gallerySchema);

    route.params.subscribe(params => {
      this.currentPage = params.page || 1;
    });

    combineLatest(entities$, photosPageContent$)
      .pipe(
        takeUntil(this.destroy$),
        throttleTime(500, undefined, { leading: true, trailing: true })
      )
      .subscribe(([entities, photosPageContent]) => {
        this.items = [];
        if (photosPageContent.galleryId) {
          const galleryItem: IGalleryItem = denormalize(
            photosPageContent.galleryId,
            gallerySchema,
            entities
          );
          if (galleryItem) {
            this.items = galleryItem.mediaItems;
          }
        }
        this.inProcess = photosPageContent.inProcess;
        this.hasErrors = photosPageContent.hasErrors;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  changePageNumber(page: number) {
    this.router.navigate([
      "/photos",
      {
        page
      }
    ]);
  }
}
