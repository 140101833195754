import { Pipe, PipeTransform } from "@angular/core";
import { getImageThumbUrl } from "../../utils/images";

@Pipe({
  name: "imageThumbUrl"
})
export class ImageThumbUrlPipe implements PipeTransform {
  transform(filePath: string): any {
    return getImageThumbUrl(filePath);
  }
}
