import { Pipe, PipeTransform } from "@angular/core";
import { IGroupedByYearNewsList, INewsItem } from "../../interfaces/news";
import * as moment from "moment";

@Pipe({
  name: "groupNewsByYear"
})
export class GroupNewsByYearPipe implements PipeTransform {
  transform(items: INewsItem[]): IGroupedByYearNewsList[] {
    const groupedItemsObj: Record<string, INewsItem[]> = {};
    for (const item of items) {
      const year: string = moment(item.articleAt).format("YYYY");

      if (!groupedItemsObj[year]) {
        groupedItemsObj[year] = [];
      }
      groupedItemsObj[year].push(item);
    }

    const groupedItems: IGroupedByYearNewsList[] = [];
    for (const year in groupedItemsObj) {
      groupedItems.push({
        year: Number(year),
        items: groupedItemsObj[year]
      });
    }
    return groupedItems.sort((a, b) => b.year - a.year);
  }
}
