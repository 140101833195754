import { handleActions } from "redux-actions";
import { IReduxAction } from "../interfaces/redux-action";
import {
  INIT_CURRENT_USER_ERROR,
  INIT_CURRENT_USER_SUCCESS,
  LOGOUT_SUCCESS,
  SIGN_IN_SUCCESS
} from "../constants/auth.constants";
import {
  GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS,
  GET_ALL_PAGES_CONTENT_LIST_SUCCESS
} from "../constants/page-content.constants";
import {
  IPageContentItem,
  IPagesContentList
} from "../../interfaces/page-content";
import { IUserItem } from "../../interfaces/user";
import { GET_CURRENT_USER_SUCCESS } from "../constants/user.constants";
import { INormalizrUserItem } from "../../normalizr/interfaces/user";

const initCurrentUserError = (
  state: string | null,
  { payload }: IReduxAction<any>
): string | null => null;

const getAllPagesContentListSuccess = (
  state: Record<string, string | null>,
  { payload }: IReduxAction<IPagesContentList>
) => {
  const pathPrefix = "/user/";

  const user: IPageContentItem | undefined = payload.items.find(item =>
    item.path.startsWith(pathPrefix)
  );

  if (user) {
    return user.path;
  }

  return state;
};

const logoutSuccess = (
  state: string | null,
  { payload }: IReduxAction<void>
): string | null => null;

const initCurrentUserSuccess = (
  state: string | null,
  { payload }: IReduxAction<INormalizrUserItem | null>
): string | null => (payload ? payload.result : null);

const signInSuccess = (
  state: string | null,
  { payload }: IReduxAction<INormalizrUserItem>
): string | null => payload.result;

export const userId = handleActions(
  {
    [INIT_CURRENT_USER_ERROR]: initCurrentUserError,
    [LOGOUT_SUCCESS]: logoutSuccess,
    [INIT_CURRENT_USER_SUCCESS]: initCurrentUserSuccess,
    [SIGN_IN_SUCCESS]: signInSuccess
    // [GET_ALL_PAGES_CONTENT_LIST_SUCCESS]: getAllPagesContentListSuccess,
    // [GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS]: getAllPagesContentListSuccess
  },
  null
);
