import { handleActions } from "redux-actions";
import { IReduxAction } from "../../interfaces/redux-action";
import {
  GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS,
  GET_ALL_PAGES_CONTENT_LIST_SUCCESS
} from "../../constants/page-content.constants";
import { IPagesContentList } from "../../../interfaces/page-content";
import { normalize } from "normalizr";
import { IGalleryItem } from "../../../interfaces/gallery";
import {
  INormalizrEventEntity,
  INormalizrEventsList
} from "../../../normalizr/interfaces/event";
import { eventSchema } from "../../../normalizr/schemas/event.schema";

const getAllPagesContentListSuccess = (
  state: Record<string, INormalizrEventEntity>,
  { payload }: IReduxAction<IPagesContentList>
) => {
  const pathPrefix = "/events/events/";

  const items: IGalleryItem[] = payload.items
    .filter(item => item.path.startsWith(pathPrefix))
    .map(item => ({
      path: item.path,
      ...JSON.parse(item.data)
    }));

  const normalizrItems: INormalizrEventsList = normalize(
    {
      items
    },
    {
      items: [eventSchema]
    }
  );

  return Object.assign({}, state, normalizrItems.entities.events);
};

export const eventEntities = handleActions(
  {
    [GET_ALL_PAGES_CONTENT_LIST_SUCCESS]: getAllPagesContentListSuccess,
    [GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS]: getAllPagesContentListSuccess
  },
  null
);
