import { Component, Input } from "@angular/core";
import { IMediaItem } from "../../../interfaces/media";

@Component({
  selector: "app-photos-list-item",
  templateUrl: "./photos-list-item.component.html",
  styleUrls: ["./photos-list-item.component.scss"]
})
export class PhotosListItemComponent {
  @Input() item: IMediaItem | undefined;
}
