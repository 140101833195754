import { handleActions } from "redux-actions";
import { IReduxAction } from "../../interfaces/redux-action";
import {
  GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS,
  GET_ALL_PAGES_CONTENT_LIST_SUCCESS
} from "../../constants/page-content.constants";
import { IPagesContentList } from "../../../interfaces/page-content";
import { IReduxListContent } from "../../interfaces/content/list-content";
import * as moment from "moment";

const getNewsletterIds = (payload: IPagesContentList): string[] => {
  const currentYear: number = Number(moment().format("YYYY"));

  const pathPrefix = "/news/newsletters/";
  return payload.items
    .filter(item => item.path.startsWith(pathPrefix))
    .filter(item => {
      const parsedData = JSON.parse(item.data);

      const itemYear: number = Number(
        moment(parsedData.articleAt).format("YYYY")
      );

      return itemYear < currentYear;
    })
    .map(item => item.path);
};

const getAllPagesContentListFirstChunkSuccess = (
  state: IReduxListContent,
  { payload }: IReduxAction<IPagesContentList>
): IReduxListContent => {
  const newsletterIds: string[] = getNewsletterIds(payload);

  const inProcess: boolean = payload.nextToken ? true : false;

  return Object.assign({}, state, {
    inProcess,
    hasErrors: false,
    items: newsletterIds
  });
};

const getAllPagesContentListSuccess = (
  state: IReduxListContent,
  { payload }: IReduxAction<IPagesContentList>
): IReduxListContent => {
  const newsletterIds: string[] = getNewsletterIds(payload);

  const inProcess: boolean = payload.nextToken ? true : false;

  return Object.assign({}, state, {
    inProcess,
    hasErrors: false,
    items: [state.items, ...newsletterIds]
  });
};

export const newsArchivePageContent = handleActions(
  {
    [GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS]: getAllPagesContentListFirstChunkSuccess,
    [GET_ALL_PAGES_CONTENT_LIST_SUCCESS]: getAllPagesContentListSuccess
  },
  null
);
