import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { debounce as _debounce } from "lodash";
import { Storage } from "@ionic/storage";
import { isPlatformBrowser } from "@angular/common";

const debug = require("debug")("aerosmith:redux");

@Injectable({
  providedIn: "root"
})
export class ReduxStateKeeperService {
  constructor(
    private storage: Storage,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  // Сохраняем состояние не чаще одного раза в 2 секунды
  private saveState = _debounce(
    store => {
      this.storage.set("state", store.getState()).catch(err => {
        debug("save to state error", err);

        this.storage.remove("state");
      });
    },
    2000,
    {
      leading: true
    }
  );

  middleware = store => next => action => {
    const result = next(action);

    if (isPlatformBrowser(this.platformId)) {
      this.saveState(store);
    }

    return result;
  };
}
