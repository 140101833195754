import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from "@angular/core";
import { PaginationControlsDirective } from "ngx-pagination";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.sass"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent implements OnInit {
  @Input() paginationApi: PaginationControlsDirective | undefined;

  /**
   * Show pages from currentPage - interval to currentPage + interval
   * @type {number}
   */
  private interval: number = 4;

  constructor() {}

  ngOnInit() {}

  getPages(): number[] {
    const paginationApi: PaginationControlsDirective = this
      .paginationApi as PaginationControlsDirective;

    const currentPageNumber = paginationApi.getCurrent();
    const lastPageNumber = paginationApi.getLastPage();

    const startPageNumber = currentPageNumber - this.interval;
    const endPageNumber = currentPageNumber + this.interval;

    const pages: number[] = [];
    for (
      let pageNumber = startPageNumber;
      pageNumber <= endPageNumber;
      pageNumber++
    ) {
      if (pageNumber >= 1 && pageNumber <= lastPageNumber) {
        pages.push(pageNumber);
      }
    }
    return pages;
  }
}
