import { Component, Renderer2 } from "@angular/core";

@Component({
  selector: "app-header-navigation-menu",
  templateUrl: "./header-navigation-menu.component.html",
  styleUrls: ["./header-navigation-menu.component.scss"]
})
export class HeaderNavigationMenuComponent {
  expandedSubmenu: string | undefined;

  constructor(private renderer: Renderer2) {}

  openNav() {
    this.renderer.addClass(document.documentElement, "nav-on");
  }

  closeNav() {
    this.renderer.removeClass(document.documentElement, "nav-on");
  }

  toggleSubmenu(submenuId: string) {
    if (this.expandedSubmenu === submenuId) {
      this.expandedSubmenu = undefined;
    } else {
      this.expandedSubmenu = submenuId;
    }
  }
}
