import { handleActions } from "redux-actions";
import { IReduxAction } from "../../interfaces/redux-action";
import {
  GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS,
  GET_ALL_PAGES_CONTENT_LIST_SUCCESS
} from "../../constants/page-content.constants";
import { IPagesContentList } from "../../../interfaces/page-content";
import { IReduxNewsPageContent } from "../../interfaces/content/news-page-content";
import * as moment from "moment";

const getAnnouncementIds = (payload: IPagesContentList): string[] => {
  const pathPrefix = "/news/announcements/";
  return payload.items
    .filter(item => item.path.startsWith(pathPrefix))
    .map(item => item.path);
};

const getNewsletterIds = (payload: IPagesContentList): string[] => {
  const currentYear: number = Number(moment().format("YYYY"));

  const pathPrefix = "/news/newsletters/";
  return payload.items
    .filter(item => item.path.startsWith(pathPrefix))
    .filter(item => {
      const parsedData = JSON.parse(item.data);

      const itemYear: number = Number(
        moment(parsedData.articleAt).format("YYYY")
      );

      return itemYear === currentYear;
    })
    .map(item => item.path);
};

const getAllPagesContentListFirstChunkSuccess = (
  state: IReduxNewsPageContent,
  { payload }: IReduxAction<IPagesContentList>
): IReduxNewsPageContent => {
  const announcementIds: string[] = getAnnouncementIds(payload);
  const newsletterIds: string[] = getNewsletterIds(payload);

  const inProcess: boolean = payload.nextToken ? true : false;

  return Object.assign({}, state, {
    announcements: {
      inProcess,
      hasErrors: false,
      items: announcementIds
    },
    newsletters: {
      inProcess,
      hasErrors: false,
      items: newsletterIds
    }
  });
};

const getAllPagesContentListSuccess = (
  state: IReduxNewsPageContent,
  { payload }: IReduxAction<IPagesContentList>
): IReduxNewsPageContent => {
  const announcementIds: string[] = getAnnouncementIds(payload);
  const newsletterIds: string[] = getNewsletterIds(payload);

  const inProcess: boolean = payload.nextToken ? true : false;

  return Object.assign({}, state, {
    announcements: {
      inProcess,
      hasErrors: false,
      items: [state.announcements.items, ...announcementIds]
    },
    newsletters: {
      inProcess,
      hasErrors: false,
      items: [state.newsletters.items, ...newsletterIds]
    }
  });
};

export const newsPageContent = handleActions(
  {
    [GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS]: getAllPagesContentListFirstChunkSuccess,
    [GET_ALL_PAGES_CONTENT_LIST_SUCCESS]: getAllPagesContentListSuccess
  },
  null
);
