import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { ReduxStateKeeperService } from "./redux-state-keeper.service";
import { NgRedux } from "@angular-redux/store";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import { Storage } from "@ionic/storage";
import { from, Observable, of } from "rxjs";
import { flatMap, map } from "rxjs/operators";
import { isPlatformServer } from "@angular/common";
import { NGX_REDUX_CONFIG_TOKEN } from "../constants/ngx-redux-config-token.constants";
import { INgxReduxConfig } from "../interfaces/ngx-redux-config.interface";
import { Reducer } from "redux";

declare var window: any;

@Injectable({
  providedIn: "root"
})
export class StoreConfiguratorService {
  constructor(
    public redux: NgRedux<any>,
    private storage: Storage,
    private reduxStateKeeper: ReduxStateKeeperService,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(NGX_REDUX_CONFIG_TOKEN) private ngReduxConfig: INgxReduxConfig
  ) {}

  configureStore(isProduction: boolean): Observable<void> {
    if (isProduction) {
      return this.configureProdStore();
    }
    return this.configureDevStore();
  }

  private configureProdStore(): Observable<void> {
    const rootReducer: Reducer = this.ngReduxConfig.rootReducer;

    return this.getState().pipe(
      map(state => {
        this.redux.configureStore(rootReducer, state, [
          this.reduxStateKeeper.middleware
        ]);
      })
    );
  }

  private configureDevStore(): Observable<void> {
    const enhancers: any[] = [];
    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
      enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const rootReducer: Reducer = this.ngReduxConfig.rootReducer;

    return this.getState().pipe(
      map(state => {
        this.redux.configureStore(
          rootReducer,
          state,
          [reduxImmutableStateInvariant(), this.reduxStateKeeper.middleware],
          enhancers
        );
      })
    );
  }

  private getState(): Observable<any> {
    const initialAppState: any = this.ngReduxConfig.initialAppState;

    if (isPlatformServer(this.platformId)) {
      return of(initialAppState);
    }

    return from(this.storage.get("state")).pipe(
      map((stateFromStorage: any) => {
        let state: any;
        if (stateFromStorage) {
          if (stateFromStorage.cacheName !== initialAppState.cacheName) {
            state = initialAppState;
          } else {
            state = stateFromStorage;
          }
        } else {
          state = initialAppState;
        }

        return state;
      })
    );
  }
}
