import {
  isArray as _isArray,
  isObject as _isObject,
  flatten as _flatten,
  zipObject as _zipObject,
  union as _union
} from "lodash";
import { schema } from "normalizr";
import Entity = schema.Entity;
import { NgRedux } from "@angular-redux/store";
import { combineLatest, Observable } from "rxjs";
import { IPartialReduxEntities } from "../redux/interfaces/entities";
import { map } from "rxjs/operators";

export const getSchemaEntitiesObservable = (
  redux: NgRedux<any>,
  ...schemaEntities: Entity[]
): Observable<IPartialReduxEntities> => {
  const keys: string[] = getSchemaKeys(...schemaEntities);

  return combineLatest(
    ...keys.map((key: string) => redux.select(["entities", key]))
  ).pipe(map((entitiesArr: any[]) => _zipObject(keys, entitiesArr)));
};

export const getSchemaKeys = (...schemaEntities: Entity[]): string[] => {
  return _union(
    ...schemaEntities.map(schemaEntity => mapValuesDeep(schemaEntity))
  );
};

const mapValuesDeep = (obj: any): string[] => {
  if (obj instanceof Entity) {
    // @ts-ignore: actually Entity has schema property
    return [obj.key].concat(mapValuesDeep(obj.schema));
  } else if (_isArray(obj)) {
    const res = obj.map(innerObj => mapValuesDeep(innerObj));
    return _flatten(res);
  } else if (_isObject(obj)) {
    const res = Object.values(obj).map(val => mapValuesDeep(val));
    return _flatten(res);
  } else {
    return [];
  }
};
