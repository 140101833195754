import { combineReducers } from "redux";
import { cacheName } from "./cache-name.reducer";
import { IAppState } from "../state/app-state.interface";
import { content } from "./content.reducer";
import { entities } from "./entities.reducer";
import { recaptchaSiteKey } from "./recaptcha-site-key.reducer";
import { isAuthenticated } from "./isAuthenticated.reducer";
import { userId } from "./user-id.reducer";

export const rootReducer = combineReducers<IAppState>({
  cacheName,
  recaptchaSiteKey,
  isAuthenticated,
  userId,
  content,
  entities
});
