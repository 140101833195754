// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

export default {
  /* Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: "us-east-1:65af7aeb-a95b-47ec-a385-62505e9d7d64",

    // REQUIRED - Amazon Cognito Region
    region: "us-east-1",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "us-east-1_5FDRgudGN",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "9rvnttpkv4atr3ndnu40aradd",

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false

    // authenticationFlowType: "USER_PASSWORD_AUTH"
  },*/
  API: {
    // graphql_endpoint:
    //   "https://o353wvcl1l.execute-api.us-east-1.amazonaws.com/dev/graph",
    // graphql_endpoint:
    //   "https://groganspoint.7.staging.bubbleup.com/api/v1/graph",

    graphql_endpoint: "https://pwa.7.staging.bubbleup.com/api/v1/graph",

    // graphql_endpoint_iam_region: "us-east-1",

    authentication_type: "NONE"

    // graphql_headers: async () => {
    //   return {
    //     site: "5"
    //   };
    // }
  }
};
