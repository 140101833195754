import { handleActions } from "redux-actions";
import { IReduxAction } from "../../interfaces/redux-action";
import {
  GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS,
  GET_ALL_PAGES_CONTENT_LIST_SUCCESS
} from "../../constants/page-content.constants";
import { normalize } from "normalizr";
import {
  INormalizrSimplePageEntity,
  INormalizrSimplePagesList
} from "../../../normalizr/interfaces/simple-page";
import { IPagesContentList } from "../../../interfaces/page-content";
import { ISimplePageItem } from "../../../interfaces/simple-page";
import { simplePageSchema } from "../../../normalizr/schemas/simple-page.schema";

const getAllPagesContentListSuccess = (
  state: Record<string, INormalizrSimplePageEntity>,
  { payload }: IReduxAction<IPagesContentList>
) => {
  const pathPrefix = "/default/";

  const items: ISimplePageItem[] = payload.items
    .filter(item => item.path.startsWith(pathPrefix))
    .map(item => ({
      path: item.path,
      ...JSON.parse(item.data)
    }));

  const normalizrItems: INormalizrSimplePagesList = normalize(
    {
      items
    },
    {
      items: [simplePageSchema]
    }
  );

  return Object.assign({}, state, normalizrItems.entities.simplePages);
};

export const simplePageEntities = handleActions(
  {
    [GET_ALL_PAGES_CONTENT_LIST_SUCCESS]: getAllPagesContentListSuccess,
    [GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS]: getAllPagesContentListSuccess
  },
  null
);
