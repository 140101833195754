import { combineReducers } from "redux";
import { newsPageContent } from "./content/news-page-content.reducer";
import { tourPageContent } from "./content/tour-page-content.reducer";
import { galleriesPageContent } from "./content/galleries-page-content.reducer";
import { videosPageContent } from "./content/videos-page-content.reducer";
import { welcomePageContent } from "./content/welcome-page-content.reducer";
import { cartContent } from "./content/cart-content.reducer";
import { eventsPageContent } from "./content/events-page-content.reducer";
import { photosPageContent } from "./content/photos-page-content.reducer";
import { executiveBoardPageContent } from "./content/executive-board-page-content.reducer";
import { boardOfDirectorsPageContent } from "./content/board-of-directors-page-content.reducer";
import { newsArchivePageContent } from "./content/news-archive-page-content.reducer";

export const content = combineReducers<any>({
  newsPage: newsPageContent,
  newsArchivePage: newsArchivePageContent,
  tourPage: tourPageContent,
  galleriesPage: galleriesPageContent,
  videosPage: videosPageContent,
  welcomePage: welcomePageContent,
  cart: cartContent,
  eventsPage: eventsPageContent,
  photosPage: photosPageContent,
  executiveBoardPage: executiveBoardPageContent,
  boardOfDirectorsPage: boardOfDirectorsPageContent
});
