import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import {
  IFormError,
  markAllFormControlsAsTouched,
  setFormErrors,
  validateEqual
} from "@bubbleup/ngx-form-validation";
import { Platform } from "@ionic/angular";
import { ISubscribeToNewsletterInput } from "../../../interfaces/subscription";
import { combineLatest } from "rxjs";
import { startWith } from "rxjs/operators";
import { countries } from "../../../data/countries";
import { states } from "../../../data/states";

@Component({
  selector: "app-email-updates-form",
  templateUrl: "./email-updates-form.component.html",
  styleUrls: ["./email-updates-form.component.scss"]
})
export class EmailUpdatesFormComponent implements OnChanges {
  group: FormGroup;

  @Input()
  inProcess: boolean = false;

  @Input() serverErrors: IFormError[] = [];

  // @todo: ildar. temporary disable recaptcha for ios
  isIos: boolean;

  @Input() recaptchaSiteKey: string = "";

  recaptchaSize: string;

  @Output() submit: EventEmitter<
    ISubscribeToNewsletterInput
  > = new EventEmitter<ISubscribeToNewsletterInput>();

  countries: any[] = countries;
  states: any[] = states;

  constructor(private formBuilder: FormBuilder, private platform: Platform) {
    this.isIos = this.platform.is("ios");

    this.group = this.formBuilder.group(
      {
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        email: ["", [Validators.required, Validators.email]],
        confirmEmail: ["", [Validators.required, Validators.email]],

        phone: [""],
        postalCode: ["", Validators.required],
        address: [""],
        city: [""],
        country: ["US", Validators.required],
        stateProvinceRegion: [""],
        birthDate: [""],
        birthDateDay: [""],
        birthDateMonth: [""],
        birthDateYear: [""],
        gender: [""],

        ageConfirm: [false, Validators.requiredTrue]
        // recaptcha: ["", Validators.required]
      },
      {
        validator: validateEqual("email", "confirmEmail")
      }
    );

    if (!this.isIos) {
      this.group.setControl(
        "recaptcha",
        new FormControl("", Validators.required)
      );
    }

    this.recaptchaSize = this.platform.is("mobile") ? "compact" : "normal";

    const birthDateDayControl: FormControl = this.group.get(
      "birthDateDay"
    ) as FormControl;
    const birthDateMonthControl: FormControl = this.group.get(
      "birthDateMonth"
    ) as FormControl;
    const birthDateYearControl: FormControl = this.group.get(
      "birthDateYear"
    ) as FormControl;

    combineLatest(
      birthDateDayControl.valueChanges.pipe(
        startWith(birthDateDayControl.value)
      ),
      birthDateMonthControl.valueChanges.pipe(
        startWith(birthDateMonthControl.value)
      ),
      birthDateYearControl.valueChanges.pipe(
        startWith(birthDateYearControl.value)
      )
    ).subscribe(([day, month, year]) => {
      const birthDateControl = this.group.get("birthDate") as FormControl;

      if (day !== "" && month !== "" && year !== "") {
        const birthDateValue = year + "-" + month + "-" + day;

        birthDateControl.setValue(birthDateValue);

        birthDateControl.markAsTouched();
      } else {
        birthDateControl.setValue("");
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.serverErrors) {
      const currentValue: IFormError[] = changes.serverErrors.currentValue;
      if (currentValue.length) {
        setFormErrors(this.group, currentValue);
      }
    }
  }

  clearStateProvinceRegion() {
    const stateProvinceRegion = this.group.get(
      "stateProvinceRegion"
    ) as FormControl;
    stateProvinceRegion.setValue("");
    stateProvinceRegion.markAsUntouched();
  }

  validateAndSubmit() {
    if (this.inProcess) {
      return;
    }
    if (this.group.invalid) {
      return markAllFormControlsAsTouched(this.group);
    }

    this.submit.emit({
      first_name: this.group.value.firstName,
      last_name: this.group.value.lastName,
      email: this.group.value.email,
      phone: this.group.value.phone,
      postal_code: this.group.value.postalCode,
      address: this.group.value.address,
      city: this.group.value.city,
      country: this.group.value.country,
      state: this.group.value.stateProvinceRegion,
      date_birth: this.group.value.birthDate,
      gender: this.group.value.gender,
      over16: this.group.value.ageConfirm
    });
  }
}
