import { Injectable } from "@angular/core";
import { IAppState } from "../redux/state/app-state.interface";
import { Observable, from, throwError, EMPTY, Subject } from "rxjs";
import { catchError, tap, expand, takeUntil, flatMap } from "rxjs/operators";
import { NgRedux } from "@angular-redux/store";
import { IPagesContentList } from "../interfaces/page-content";
import {
  getAllPagesContentListError,
  getAllPagesContentListFirstChunkSuccess,
  getAllPagesContentListRequest,
  getAllPagesContentListSuccess
} from "../redux/actions/page-content.actions";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";

const siteId: string = "5";

@Injectable({
  providedIn: "root"
})
export class TotalApiService {
  private apiCallInProcess$: Subject<boolean> = new Subject<boolean>();

  constructor(private redux: NgRedux<IAppState>, private http: HttpClient) {}

  getAllPagesContentList(
    withCachedPreRequest: boolean = false
  ): Observable<any> {
    this.apiCallInProcess$.next(true);

    this.redux.dispatch(getAllPagesContentListRequest());

    // if guest then first we do quick request to cached data. Then we get data from API.
    let start$: Observable<any>;
    if (withCachedPreRequest) {
      start$ = this.getCachedDataFromBackend().pipe(
        takeUntil(this.apiCallInProcess$),
        tap((pagesContentList: IPagesContentList) => {
          this.redux.dispatch(
            getAllPagesContentListFirstChunkSuccess(pagesContentList)
          );
          this.redux.dispatch(getAllPagesContentListRequest());
        }),
        flatMap(() => this.callApi(siteId, null))
      );
    } else {
      start$ = this.callApi(siteId, null);
    }

    return start$.pipe(
      takeUntil(this.apiCallInProcess$),
      expand((pagesContentList: IPagesContentList, index: number) => {
        if (index === 0) {
          this.redux.dispatch(
            getAllPagesContentListFirstChunkSuccess(pagesContentList)
          );
        } else {
          this.redux.dispatch(getAllPagesContentListSuccess(pagesContentList));
        }

        if (pagesContentList.nextToken) {
          return this.callApi(siteId, pagesContentList.nextToken).pipe(
            takeUntil(this.apiCallInProcess$)
          );
        } else {
          return EMPTY;
        }
      }),
      catchError(err => {
        this.redux.dispatch(getAllPagesContentListError(err));
        return throwError(err);
      })
    );
  }

  searchContent(queryVars: any): Observable<any> {
    // this.redux.dispatch(searchContentRequest());
    return from(this.callSearchApi(queryVars)).pipe(
      // takeUntil(this.apiCallInProcess$),
      catchError(err => {
        // this.redux.dispatch(getAllPagesContentListError(err));
        return throwError(err);
      })
    );
  }

  private getCachedDataFromBackend(): Observable<any> {
    return this.http.get(environment.cachedDataUrl);
  }

  private callApi(siteId: string, nextToken: string | null): Observable<any> {
    const statement = `query listAllSiteContent($siteId: ID!, $nextToken: String) { 
      listAllSiteContent(siteId: $siteId, nextToken: $nextToken) {
        items{
          path
          pathAliases
          tags
          data
        },
        nextToken
      }
    }
    `;

    const gqlAPIServiceArguments: any = {
      siteId,
      nextToken
    };

    // @ts-ignore
    return from(
      fetch("https://pwa.7.staging.bubbleup.com/api/v1/graph", {
        method: "POST",
        body: JSON.stringify({
          query: statement,
          variables: gqlAPIServiceArguments
        }), // Coordinate the body type with 'Content-Type'
        headers: new Headers({
          "Content-Type": "application/json",
          site: "5"
        })
      })
    ).pipe(
      flatMap(res => res.json()),
      map((response: any) => response.data.listAllSiteContent)
    );

    // return from<any>(api.graphql({
    //   query: statement,
    //   variables: gqlAPIServiceArguments
    // }) as Promise<any>).pipe(
    //   map((response: any) => response.data.listAllSiteContent)
    // );
  }

  private callSearchApi(queryVars: any): Observable<any> {
    const statement = `query searchContent($contentFilter: ContentFilter!) {
      searchContent(contentFilter: $contentFilter) {
        items {
          path
          pathAliases
          tags
          data
        }
        nextToken
      }
    }
    `;

    const gqlAPIServiceArguments: any = queryVars;

    // @ts-ignore
    return from(
      fetch("https://pwa.7.staging.bubbleup.com/api/v1/graph", {
        method: "POST",
        body: JSON.stringify({
          query: statement,
          variables: gqlAPIServiceArguments
        }), // Coordinate the body type with 'Content-Type'
        headers: new Headers({
          "Content-Type": "application/json",
          site: "5"
        })
      })
    ).pipe(
      flatMap(res => res.json()),
      map((response: any) => response.data.searchContent)
    );

    // return from<any>(api.graphql({
    //   query: statement,
    //   variables: gqlAPIServiceArguments
    // }) as Promise<any>).pipe(
    //   map((response: any) => response.data.searchContent)
    // );
  }
}
