import { Component, Input, OnInit } from "@angular/core";
import { INewsItem } from "../../../interfaces/news";

@Component({
  selector: "app-welcome-page-news-block",
  templateUrl: "./welcome-page-news-block.component.html",
  styleUrls: ["./welcome-page-news-block.component.scss"]
})
export class WelcomePageNewsBlockComponent {
  @Input() items: INewsItem[] = [];
  @Input() inProcess: boolean = true;
  @Input() hasErrors: boolean = false;

  trackByNews(index: number, item: INewsItem): string {
    return item.path;
  }
}
