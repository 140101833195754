import { handleActions } from "redux-actions";
import { IReduxAction } from "../interfaces/redux-action";
import {
  IPageContentItem,
  IPagesContentList
} from "../../interfaces/page-content";
import {
  GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS,
  GET_ALL_PAGES_CONTENT_LIST_SUCCESS
} from "../constants/page-content.constants";

const getAllPagesContentListSuccess = (
  state: string,
  { payload }: IReduxAction<IPagesContentList>
) => {
  const recaptchaSiteKeyPageContent:
    | IPageContentItem
    | undefined = payload.items.find(
    item => item.path === "/config/RECAPTCHA_SITE_KEY"
  );

  return recaptchaSiteKeyPageContent
    ? JSON.parse(recaptchaSiteKeyPageContent.data).value
    : state;
};

export const recaptchaSiteKey = handleActions(
  {
    // [GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS]: getAllPagesContentListSuccess,
    // [GET_ALL_PAGES_CONTENT_LIST_SUCCESS]: getAllPagesContentListSuccess
  },
  null
);
