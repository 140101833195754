import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { denormalize } from "normalizr";
import { takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { NgRedux } from "@angular-redux/store";
import {
  IPartialReduxEntities,
  IReduxEntities
} from "../../../redux/interfaces/entities";
import { ISimplePageItem } from "../../../interfaces/simple-page";
import { simplePageSchema } from "../../../normalizr/schemas/simple-page.schema";
import { IAppState } from "../../../redux/state/app-state.interface";
import { getSchemaEntitiesObservable } from "../../../utils/normalizr";
import { throttleTime } from "rxjs/operators";

@Component({
  selector: "app-page-text-container",
  templateUrl: "./page-text-container.component.html",
  styleUrls: ["./page-text-container.component.scss"]
})
export class PageTextContainerComponent implements OnInit, OnDestroy {
  simplePage: ISimplePageItem | undefined;

  @Input() pageId: string = "";

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private redux: NgRedux<IAppState>) {}

  ngOnInit() {
    const entities$: Observable<
      IPartialReduxEntities
    > = getSchemaEntitiesObservable(this.redux, simplePageSchema);

    entities$
      .pipe(
        takeUntil(this.destroy$),
        throttleTime(500, undefined, { leading: true, trailing: true })
      )
      .subscribe((entities: IPartialReduxEntities) => {
        this.simplePage = denormalize(
          "/default/" + this.pageId,
          simplePageSchema,
          entities
        );
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
