import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Renderer2
} from "@angular/core";

@Directive({
  selector: "[appHasScrolledClass]"
})
export class HasScrolledClassDirective implements AfterViewInit {
  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.setHasScrolledClass(this.el.nativeElement.offsetTop);
    }, 500);
  }

  @HostListener("ionScroll", ["$event"])
  onScroll(event) {
    this.setHasScrolledClass(event.detail.scrollTop);
  }

  private setHasScrolledClass(offsetTop: number): void {
    if (offsetTop > 0) {
      document.querySelectorAll("header").forEach(el => {
        this.renderer.addClass(el, "fixed");
      });
    } else {
      document.querySelectorAll("header").forEach(el => {
        this.renderer.removeClass(el, "fixed");
      });
    }
  }
}
