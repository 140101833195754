import { handleActions } from "redux-actions";
import { INormalizrUserItem } from "../../normalizr/interfaces/user";
import { IReduxAction } from "../interfaces/redux-action";
import {
  INIT_CURRENT_USER_ERROR,
  INIT_CURRENT_USER_SUCCESS,
  LOGOUT_SUCCESS,
  SIGN_IN_SUCCESS
} from "../constants/auth.constants";

const initCurrentUserError = (state: boolean, { payload }: IReduxAction<any>) =>
  false;

const initCurrentUserSuccess = (
  state: boolean,
  { payload }: IReduxAction<boolean>
) => payload;

const signInSuccess = (
  state: boolean,
  { payload }: IReduxAction<INormalizrUserItem>
) => true;

const logoutSuccess = (state: boolean, { payload }: IReduxAction<void>) =>
  false;

export const isAuthenticated = handleActions(
  {
    [INIT_CURRENT_USER_ERROR]: initCurrentUserError,
    [INIT_CURRENT_USER_SUCCESS]: initCurrentUserSuccess,
    [SIGN_IN_SUCCESS]: signInSuccess,
    [LOGOUT_SUCCESS]: logoutSuccess
  },
  null
);
