import { handleActions } from "redux-actions";
import { IReduxAction } from "../../interfaces/redux-action";
import {
  GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS,
  GET_ALL_PAGES_CONTENT_LIST_SUCCESS
} from "../../constants/page-content.constants";
import {
  IPageContentItem,
  IPagesContentList
} from "../../../interfaces/page-content";
import { IReduxSingleGalleryContent } from "../../interfaces/content/single-gallery-content";

const getMainGalleryId = (payload: IPagesContentList): string | undefined => {
  const pathPrefix = "/gallery/";

  const galleryContentItem: IPageContentItem | undefined = payload.items.find(
    item => {
      if (item.path.startsWith(pathPrefix)) {
        const data = JSON.parse(item.data);
        return data.name === "board-of-directors";
      }
      return false;
    }
  );

  if (galleryContentItem) {
    return galleryContentItem.path;
  } else {
    return undefined;
  }
};

const getAllPagesContentListSuccess = (
  state: IReduxSingleGalleryContent,
  { payload }: IReduxAction<IPagesContentList>
): IReduxSingleGalleryContent => {
  const galleryId: string | undefined = getMainGalleryId(payload);

  if (galleryId) {
    return {
      inProcess: false,
      hasErrors: false,
      galleryId
    };
  } else {
    return state;
  }
};

export const boardOfDirectorsPageContent = handleActions(
  {
    [GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS]: getAllPagesContentListSuccess,
    [GET_ALL_PAGES_CONTENT_LIST_SUCCESS]: getAllPagesContentListSuccess
  },
  null
);
