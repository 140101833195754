import { Component, Input, OnInit } from "@angular/core";
import { INewsItem } from "../../../interfaces/news";
import { IEventItem } from "../../../interfaces/event";

@Component({
  selector: "app-events-page-events-block",
  templateUrl: "./events-page-events-block.component.html",
  styleUrls: ["./events-page-events-block.component.scss"]
})
export class EventsPageEventsBlockComponent {
  @Input() items: INewsItem[] = [];
  @Input() inProcess: boolean = true;
  @Input() hasErrors: boolean = false;

  showNewsItemDescriptionIndex: number = -1;

  trackByEvent(index: number, item: IEventItem): string {
    return item.path;
  }
}
