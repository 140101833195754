import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { IMediaItem } from "../../../interfaces/media";

@Component({
  selector: "app-single-media-modal",
  templateUrl: "./single-media-modal.component.html",
  styleUrls: ["./single-media-modal.component.scss"]
})
export class SingleMediaModalComponent {
  @Input() item: IMediaItem | undefined;

  constructor(private modalCtrl: ModalController) {}

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
