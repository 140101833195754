import { Component, Input } from "@angular/core";
import { INewsItem } from "../../../interfaces/news";

@Component({
  selector: "app-newsletter-archive",
  templateUrl: "./newsletter-archive.component.html",
  styleUrls: ["./newsletter-archive.component.scss"]
})
export class NewsletterArchiveComponent {
  @Input() items: INewsItem[] = [];
  @Input() inProcess: boolean = true;
  @Input() hasErrors: boolean = false;

  constructor() {}

  trackByNews(index: number, news: INewsItem): string {
    return news.path;
  }
}
