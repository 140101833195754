import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-parent-with-header-and-footer",
  templateUrl: "./parent-with-header-and-footer.page.html",
  styleUrls: ["./parent-with-header-and-footer.page.scss"]
})
export class ParentWithHeaderAndFooterPage implements OnInit {
  constructor() {}

  ngOnInit() {}
}
