import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-welcome-page-content-blocks",
  templateUrl: "./welcome-page-content-blocks.component.html",
  styleUrls: ["./welcome-page-content-blocks.component.scss"]
})
export class WelcomePageContentBlocksComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
