import { Directive, ElementRef, OnInit } from "@angular/core";

@Directive({
  selector: "[appRemoveWrapper]"
})
export class RemoveWrapperDirective implements OnInit {
  constructor(private el: ElementRef) {}

  ngOnInit() {
    const parentElement = this.el.nativeElement.parentElement;
    const element = this.el.nativeElement;
    parentElement.removeChild(element);
    parentElement.parentNode.insertBefore(element, parentElement.nextSibling);
    parentElement.parentNode.removeChild(parentElement);
  }
}
