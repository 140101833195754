import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-welcome-page-options-block",
  templateUrl: "./welcome-page-options-block.component.html",
  styleUrls: ["./welcome-page-options-block.component.scss"]
})
export class WelcomePageOptionsBlockComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
