import { Injectable } from "@angular/core";
import { IContactFormData } from "../interfaces/contact-form";
import { throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { SubmitContentService } from "./submit-content.service";

@Injectable({
  providedIn: "root"
})
export class FeedbackService {
  constructor(private submitContentService: SubmitContentService) {}

  submit(data: IContactFormData) {
    // this.redux.dispatch(subscribeToNewsletterRequest(subscriptionInfo));
    return this.submitContentService
      .submit("/form/contact-form", {
        form_data: {
          ContactForm2: data
        }
      })
      .pipe(
        tap(() => {
          // this.redux.dispatch(subscribeToNewsletterSuccess(subscriptionInfo));
        }),
        catchError((err: any) => {
          // this.redux.dispatch(subscribeToNewsletterError(err));
          return throwError(err);
        })
      );
  }
}
