import { Component, OnDestroy } from "@angular/core";
import { combineLatest, Observable, Subject } from "rxjs";
import { NgRedux } from "@angular-redux/store";
import { takeUntil, throttleTime } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { IAppState } from "../../../redux/state/app-state.interface";
import { getSchemaEntitiesObservable } from "../../../utils/normalizr";
import { denormalize } from "normalizr";
import { IPartialReduxEntities } from "../../../redux/interfaces/entities";
import { IEventItem } from "../../../interfaces/event";
import { eventSchema } from "../../../normalizr/schemas/event.schema";

@Component({
  selector: "app-event-details-page-event-block-container",
  templateUrl: "./event-details-page-event-block-container.component.html",
  styleUrls: ["./event-details-page-event-block-container.component.scss"]
})
export class EventDetailsPageEventBlockContainerComponent implements OnDestroy {
  item: IEventItem | undefined;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(route: ActivatedRoute, redux: NgRedux<IAppState>) {
    const entities$: Observable<
      IPartialReduxEntities
    > = getSchemaEntitiesObservable(redux, eventSchema);

    combineLatest(entities$, route.params)
      .pipe(
        takeUntil(this.destroy$),
        throttleTime(500, undefined, { leading: true, trailing: true })
      )
      .subscribe(([entities, params]) => {
        this.item = denormalize(params.path, eventSchema, entities);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
