// Get all pages content list (begin)
export const GET_ALL_PAGES_CONTENT_LIST_REQUEST =
  "GET_ALL_PAGES_CONTENT_LIST_REQUEST";
export const GET_ALL_PAGES_CONTENT_LIST_SUCCESS =
  "GET_ALL_PAGES_CONTENT_LIST_SUCCESS";
export const GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS =
  "GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS";
export const GET_ALL_PAGES_CONTENT_LIST_ERROR =
  "GET_ALL_PAGES_CONTENT_LIST_ERROR";
// Get all pages content list (end)
