import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-news-page-content-block-item",
  templateUrl: "./news-page-content-block-item.component.html",
  styleUrls: ["./news-page-content-block-item.component.scss"]
})
export class NewsPageContentBlockItemComponent implements OnInit {
  @Input() title: string | undefined;

  constructor() {}

  ngOnInit() {}
}
