export const states: any[] = [
  { code: "AL", name: "Alabama" },
  { code: "AK", name: "Alaska" },
  {
    code: "AA",
    name: "APO - AA"
  },
  { code: "AE", name: "APO - AE" },
  { code: "AP", name: "APO - AP" },
  {
    code: "AZ",
    name: "Arizona"
  },
  { code: "AR", name: "Arkansas" },
  { code: "CA", name: "California" },
  {
    code: "CO",
    name: "Colorado"
  },
  { code: "CT", name: "Connecticut" },
  { code: "DE", name: "Delaware" },
  {
    code: "DC",
    name: "District of Columbia"
  },
  { code: "FL", name: "Florida" },
  { code: "GA", name: "Georgia" },
  {
    code: "HI",
    name: "Hawaii"
  },
  { code: "ID", name: "Idaho" },
  { code: "IL", name: "Illinois" },
  {
    code: "IN",
    name: "Indiana"
  },
  { code: "IA", name: "Iowa" },
  { code: "KS", name: "Kansas" },
  {
    code: "KY",
    name: "Kentucky"
  },
  { code: "LA", name: "Louisiana" },
  { code: "ME", name: "Maine" },
  {
    code: "MD",
    name: "Maryland"
  },
  { code: "MA", name: "Massachusetts" },
  { code: "MI", name: "Michigan" },
  {
    code: "MN",
    name: "Minnesota"
  },
  { code: "MS", name: "Mississippi" },
  { code: "MO", name: "Missouri" },
  {
    code: "MT",
    name: "Montana"
  },
  { code: "NE", name: "Nebraska" },
  { code: "NV", name: "Nevada" },
  {
    code: "NH",
    name: "New Hampshire"
  },
  { code: "NJ", name: "New Jersey" },
  { code: "NM", name: "New Mexico" },
  {
    code: "NY",
    name: "New York"
  },
  { code: "NC", name: "North Carolina" },
  { code: "ND", name: "North Dakota" },
  {
    code: "OH",
    name: "Ohio"
  },
  { code: "OK", name: "Oklahoma" },
  { code: "OR", name: "Oregon" },
  {
    code: "PA",
    name: "Pennsylvania"
  },
  { code: "RI", name: "Rhode Island" },
  { code: "SC", name: "South Carolina" },
  {
    code: "SD",
    name: "South Dakota"
  },
  { code: "TN", name: "Tennessee" },
  { code: "TX", name: "Texas" },
  {
    code: "UT",
    name: "Utah"
  },
  { code: "VT", name: "Vermont" },
  { code: "VA", name: "Virginia" },
  {
    code: "WA",
    name: "Washington"
  },
  { code: "WV", name: "West Virginia" },
  { code: "WI", name: "Wisconsin" },
  {
    code: "WY",
    name: "Wyoming"
  },
  { code: "AB", name: "Alberta" },
  { code: "BC", name: "British Columbia" },
  {
    code: "MB",
    name: "Manitoba"
  },
  { code: "NB", name: "New Brunswick" },
  { code: "NF", name: "Newfoundland" },
  {
    code: "NT",
    name: "Northwest Territories"
  },
  { code: "NS", name: "Nova Scotia" },
  { code: "NU", name: "Nunavut" },
  {
    code: "ON",
    name: "Ontario"
  },
  { code: "PE", name: "Prince Edward Island" },
  { code: "PQ", name: "Quebec" },
  {
    code: "SK",
    name: "Saskatchewan"
  },
  { code: "YT", name: "Yukon" },
  { code: "I0", name: "Aberconwy and Colwyn" },
  {
    code: "I1",
    name: "Aberdeen City"
  },
  { code: "I2", name: "Aberdeenshire" },
  { code: "I3", name: "Anglesey" },
  {
    code: "I4",
    name: "Angus"
  },
  { code: "I5", name: "Antrim" },
  { code: "I6", name: "Argyll and Bute" },
  {
    code: "I7",
    name: "Armagh"
  },
  { code: "I8", name: "Avon" },
  { code: "I9", name: "Ayrshire" },
  {
    code: "IB",
    name: "Bath and NE Somerset"
  },
  { code: "IC", name: "Bedfordshire" },
  { code: "IE", name: "Belfast" },
  {
    code: "IF",
    name: "Berkshire"
  },
  { code: "IG", name: "Berwickshire" },
  { code: "IH", name: "BFPO" },
  {
    code: "II",
    name: "Blaenau Gwent"
  },
  { code: "IJ", name: "Buckinghamshire" },
  { code: "IK", name: "Caernarfonshire" },
  {
    code: "IM",
    name: "Caerphilly"
  },
  { code: "IO", name: "Caithness" },
  { code: "IP", name: "Cambridgeshire" },
  {
    code: "IQ",
    name: "Cardiff"
  },
  { code: "IR", name: "Cardiganshire" },
  { code: "IS", name: "Carmarthenshire" },
  {
    code: "IT",
    name: "Ceredigion"
  },
  { code: "IU", name: "Channel Islands" },
  { code: "IV", name: "Cheshire" },
  {
    code: "IW",
    name: "City of Bristol"
  },
  { code: "IX", name: "Clackmannanshire" },
  { code: "IY", name: "Clwyd" },
  {
    code: "IZ",
    name: "Conwy"
  },
  { code: "J0", name: "Cornwall/Scilly" },
  { code: "J1", name: "Cumbria" },
  {
    code: "J2",
    name: "Denbighshire"
  },
  { code: "J3", name: "Derbyshire" },
  { code: "J4", name: "Derry/Londonderry" },
  {
    code: "J5",
    name: "Devon"
  },
  { code: "J6", name: "Dorset" },
  { code: "J7", name: "Down" },
  {
    code: "J8",
    name: "Dumfries and Galloway"
  },
  { code: "J9", name: "Dunbartonshire" },
  { code: "JA", name: "Dundee" },
  {
    code: "JB",
    name: "Durham"
  },
  { code: "JC", name: "Dyfed" },
  { code: "JD", name: "East Ayrshire" },
  {
    code: "JE",
    name: "East Dunbartonshire"
  },
  { code: "JF", name: "East Lothian" },
  { code: "JG", name: "East Renfrewshire" },
  {
    code: "JH",
    name: "East Riding Yorkshire"
  },
  { code: "JI", name: "East Sussex" },
  { code: "JJ", name: "Edinburgh" },
  {
    code: "JK",
    name: "England"
  },
  { code: "JL", name: "Essex" },
  { code: "JM", name: "Falkirk" },
  {
    code: "JN",
    name: "Fermanagh"
  },
  { code: "JO", name: "Fife" },
  { code: "JP", name: "Flintshire" },
  {
    code: "JQ",
    name: "Glasgow"
  },
  { code: "JR", name: "Gloucestershire" },
  { code: "JS", name: "Greater London" },
  {
    code: "JT",
    name: "Greater Manchester"
  },
  { code: "JU", name: "Gwent" },
  { code: "JV", name: "Gwynedd" },
  {
    code: "JW",
    name: "Hampshire"
  },
  { code: "JX", name: "Hartlepool" },
  { code: "HAW", name: "Hereford and Worcester" },
  {
    code: "JY",
    name: "Hertfordshire"
  },
  { code: "JZ", name: "Highlands" },
  { code: "K0", name: "Inverclyde" },
  {
    code: "K1",
    name: "Inverness-Shire"
  },
  { code: "K2", name: "Isle of Man" },
  { code: "K3", name: "Isle of Wight" },
  {
    code: "K4",
    name: "Kent"
  },
  { code: "K5", name: "Kincardinshire" },
  { code: "K6", name: "Kingston Upon Hull" },
  {
    code: "K7",
    name: "Kinross-Shire"
  },
  { code: "K8", name: "Kirklees" },
  { code: "K9", name: "Lanarkshire" },
  {
    code: "KA",
    name: "Lancashire"
  },
  { code: "KB", name: "Leicestershire" },
  { code: "KC", name: "Lincolnshire" },
  {
    code: "KD",
    name: "Londonderry"
  },
  { code: "KE", name: "Merseyside" },
  { code: "KF", name: "Merthyr Tydfil" },
  {
    code: "KG",
    name: "Mid Glamorgan"
  },
  { code: "KI", name: "Mid Lothian" },
  { code: "KH", name: "Middlesex" },
  {
    code: "KJ",
    name: "Monmouthshire"
  },
  { code: "KK", name: "Moray" },
  { code: "KL", name: "Neath & Port Talbot" },
  {
    code: "KM",
    name: "Newport"
  },
  { code: "KN", name: "Norfolk" },
  { code: "KP", name: "North Ayrshire" },
  {
    code: "KQ",
    name: "North East Lincolnshire"
  },
  { code: "KR", name: "North Lanarkshire" },
  { code: "KT", name: "North Lincolnshire" },
  {
    code: "KU",
    name: "North Somerset"
  },
  { code: "KV", name: "North Yorkshire" },
  { code: "KO", name: "Northamptonshire" },
  {
    code: "KW",
    name: "Northern Ireland"
  },
  { code: "KX", name: "Northumberland" },
  { code: "KZ", name: "Nottinghamshire" },
  {
    code: "L0",
    name: "Orkney and Shetland Isles"
  },
  { code: "L1", name: "Oxfordshire" },
  { code: "L2", name: "Pembrokeshire" },
  {
    code: "L3",
    name: "Perth and Kinross"
  },
  { code: "L4", name: "Powys" },
  { code: "L5", name: "Redcar and Cleveland" },
  {
    code: "L6",
    name: "Renfrewshire"
  },
  { code: "L7", name: "Rhonda Cynon Taff" },
  { code: "L8", name: "Rutland" },
  {
    code: "L9",
    name: "Scottish Borders"
  },
  { code: "LB", name: "Shetland" },
  { code: "LC", name: "Shropshire" },
  {
    code: "LD",
    name: "Somerset"
  },
  { code: "LE", name: "South Ayrshire" },
  { code: "LF", name: "South Glamorgan" },
  {
    code: "LG",
    name: "South Gloucesteshire"
  },
  { code: "LH", name: "South Lanarkshire" },
  { code: "LI", name: "South Yorkshire" },
  {
    code: "LJ",
    name: "Staffordshire"
  },
  { code: "LK", name: "Stirling" },
  { code: "LL", name: "Stockton On Tees" },
  {
    code: "LM",
    name: "Suffolk"
  },
  { code: "LN", name: "Surrey" },
  { code: "LO", name: "Swansea" },
  {
    code: "LP",
    name: "Torfaen"
  },
  { code: "LQ", name: "Tyne and Wear" },
  { code: "LR", name: "Tyrone" },
  {
    code: "LS",
    name: "Vale Of Glamorgan"
  },
  { code: "LT", name: "Wales" },
  { code: "LU", name: "Warwickshire" },
  {
    code: "LV",
    name: "West Berkshire"
  },
  { code: "LW", name: "West Dunbartonshire" },
  { code: "LX", name: "West Glamorgan" },
  {
    code: "LY",
    name: "West Lothian"
  },
  { code: "LZ", name: "West Midlands" },
  { code: "M0", name: "West Sussex" },
  {
    code: "M1",
    name: "West Yorkshire"
  },
  { code: "M2", name: "Western Isles" },
  { code: "M3", name: "Wiltshire" },
  {
    code: "M4",
    name: "Wirral"
  },
  { code: "M5", name: "Worcestershire" },
  { code: "M6", name: "Wrexham" },
  {
    code: "M7",
    name: "York"
  },
  { code: "B0", name: "Australian Capital Territory" },
  { code: "B1", name: "New South Wales" },
  {
    code: "B2",
    name: "Northern Territory"
  },
  { code: "B3", name: "Queensland" },
  { code: "B4", name: "South Australia" },
  {
    code: "B5",
    name: "Tasmania"
  },
  { code: "B6", name: "Victoria" },
  { code: "B7", name: "Western Australia" }
];
