import { Component, OnDestroy } from "@angular/core";
import { IReduxSingleGalleryContent } from "../../../redux/interfaces/content/single-gallery-content";
import { combineLatest, Observable, Subject } from "rxjs";
import { IAppState } from "../../../redux/state/app-state.interface";
import { getSchemaEntitiesObservable } from "../../../utils/normalizr";
import { gallerySchema } from "../../../normalizr/schemas/gallery.schema";
import { IMediaItem } from "../../../interfaces/media";
import { takeUntil, throttleTime } from "rxjs/operators";
import { IGalleryItem } from "../../../interfaces/gallery";
import { NgRedux } from "@angular-redux/store";
import { IPartialReduxEntities } from "../../../redux/interfaces/entities";
import { denormalize } from "normalizr";

@Component({
  selector: "app-executive-board-photos-list-container",
  templateUrl: "./executive-board-photos-list-container.component.html",
  styleUrls: ["./executive-board-photos-list-container.component.scss"]
})
export class ExecutiveBoardPhotosListContainerComponent implements OnDestroy {
  items: IMediaItem[] = [];
  inProcess: boolean = true;
  hasErrors: boolean = false;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(redux: NgRedux<IAppState>) {
    const executiveBoardPageContent$: Observable<
      IReduxSingleGalleryContent
    > = redux.select(["content", "executiveBoardPage"]);
    const entities$: Observable<
      IPartialReduxEntities
    > = getSchemaEntitiesObservable(redux, gallerySchema);

    combineLatest(entities$, executiveBoardPageContent$)
      .pipe(
        takeUntil(this.destroy$),
        throttleTime(500, undefined, { leading: true, trailing: true })
      )
      .subscribe(([entities, executiveBoardPageContent]) => {
        this.items = [];
        if (executiveBoardPageContent.galleryId) {
          const galleryItem: IGalleryItem = denormalize(
            executiveBoardPageContent.galleryId,
            gallerySchema,
            entities
          );
          if (galleryItem) {
            this.items = galleryItem.mediaItems;
          }
        }
        this.inProcess = executiveBoardPageContent.inProcess;
        this.hasErrors = executiveBoardPageContent.hasErrors;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
