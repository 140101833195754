import { Component, Input, OnInit } from "@angular/core";
import { INewsItem } from "../../../interfaces/news";
import { IEventItem } from "../../../interfaces/event";

@Component({
  selector: "app-welcome-page-events-block",
  templateUrl: "./welcome-page-events-block.component.html",
  styleUrls: ["./welcome-page-events-block.component.scss"]
})
export class WelcomePageEventsBlockComponent {
  @Input() items: INewsItem[] = [];
  @Input() inProcess: boolean = true;
  @Input() hasErrors: boolean = false;

  trackByEvent(index: number, item: IEventItem): string {
    return item.path;
  }
}
