import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-header-utilities-container",
  templateUrl: "./header-utilities-container.component.html",
  styleUrls: ["./header-utilities-container.component.scss"]
})
export class HeaderUtilitiesContainerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
