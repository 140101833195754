import { createAction } from "redux-actions";
import { ISubscribeToNewsletterInput } from "../../interfaces/subscription";
import {
  SUBSCRIBE_TO_NEWSLETTER_ERROR,
  SUBSCRIBE_TO_NEWSLETTER_REQUEST,
  SUBSCRIBE_TO_NEWSLETTER_SUCCESS
} from "../constants/subscription.constants";

export const subscribeToNewsletterRequest = (
  input: ISubscribeToNewsletterInput
) => createAction(SUBSCRIBE_TO_NEWSLETTER_REQUEST)(input);

export const subscribeToNewsletterSuccess = (
  input: ISubscribeToNewsletterInput
) => createAction(SUBSCRIBE_TO_NEWSLETTER_SUCCESS)(input);

export const subscribeToNewsletterError = (err: any) =>
  createAction(SUBSCRIBE_TO_NEWSLETTER_ERROR)(err);
