import { IReduxEntities } from "../interfaces/entities";
import { combineReducers } from "redux";
import { newsEntities } from "./entities/news-entities.reducer";
import { concertEntities } from "./entities/concert-entities.reducer";
import { galleryEntities } from "./entities/gallery-entities.reducer";
import { mediaEntities } from "./entities/media-entities.reducer";
import { navigationMenuEntities } from "./entities/navigation-menu-entities.reducer";
import { simplePageEntities } from "./entities/simple-page-entities.reducer";
import { userEntities } from "./entities/user-entities.reducer";
import { cartEntities } from "./entities/cart-entities.reducer";
import { eventEntities } from "./entities/event-entities.reducer";

export const entities = combineReducers<IReduxEntities>({
  news: newsEntities,
  concerts: concertEntities,
  galleries: galleryEntities,
  medias: mediaEntities,
  navigationMenus: navigationMenuEntities,
  simplePages: simplePageEntities,
  users: userEntities,
  cart: cartEntities,
  events: eventEntities
});
