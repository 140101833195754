import { Component, Renderer2 } from "@angular/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AuthService } from "./services/auth.service";
import { TotalApiService } from "./services/total-api.service";
import { Event, NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html"
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    private totalApiService: TotalApiService,
    private router: Router,
    private renderer: Renderer2
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    // this.authService.isAuthenticated().subscribe(isAuthenticated => {
    //   this.totalApiService.getAllPagesContentList(!isAuthenticated).subscribe();
    // });
    this.totalApiService.getAllPagesContentList(false).subscribe();

    let prevUrl: string | null = null;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const currentUrl: string = event.urlAfterRedirects.split("#")[0];

        if (currentUrl !== prevUrl) {
          if (!window.location.hash.length) {
            const ionContent: any = document.querySelector(
              ".ion-page:not(ion-app):not(.ion-page-hidden) ion-content"
            );
            if (ionContent) {
              ionContent.scrollToTop();
            }
          }

          const container: any = document.querySelector(
            ".ion-page:not(ion-app):not(.ion-page-hidden) main"
          );
          if (container) {
            container.setAttribute("tabindex", "-1");
            container.focus();
          }

          this.renderer.removeClass(document.documentElement, "nav-on");
        }

        prevUrl = currentUrl;
      }
    });

    window.ngNavigateByUrl = (url: string) => {
      this.router.navigateByUrl(url);
    };
  }
}
