import { Component, Input, OnInit } from "@angular/core";
import { INewsItem } from "../../../interfaces/news";

@Component({
  selector: "app-single-news",
  templateUrl: "./single-news.component.html",
  styleUrls: ["./single-news.component.scss"]
})
export class SingleNewsComponent implements OnInit {
  @Input() item: INewsItem | undefined;

  constructor() {}

  ngOnInit() {}
}
