import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxPaginationModule } from "ngx-pagination";
import { environment } from "../../environments/environment";
import { IonicModule } from "@ionic/angular";
import { NgxCommonModule } from "@bubbleup/ngx-common";
import { NgxMydataJsModule } from "@bubbleup/ngx-mydata-js";
import { RecaptchaModule } from "ng-recaptcha";
import { RecaptchaFormsModule } from "ng-recaptcha/forms";
import { NgxFormValidationModule } from "@bubbleup/ngx-form-validation";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderNavigationMenuContainerComponent } from "./containers/header-navigation-menu-container/header-navigation-menu-container.component";
import { HeaderNavigationMenuComponent } from "./components/header-navigation-menu/header-navigation-menu.component";
import { HeaderUtilitiesContainerComponent } from "./containers/header-utilities-container/header-utilities-container.component";
import { HeaderUtilitiesComponent } from "./components/header-utilities/header-utilities.component";
import { WelcomePageSliderContainerComponent } from "./containers/welcome-page-slider-container/welcome-page-slider-container.component";
import { WelcomePageSliderComponent } from "./components/welcome-page-slider/welcome-page-slider.component";
import { WelcomePageContentBlocksComponent } from "./components/welcome-page-content-blocks/welcome-page-content-blocks.component";
import { WelcomePageContentBlockItemComponent } from "./components/welcome-page-content-block-item/welcome-page-content-block-item.component";
import { WelcomePageNewsBlockContainerComponent } from "./containers/welcome-page-news-block-container/welcome-page-news-block-container.component";
import { WelcomePageNewsBlockComponent } from "./components/welcome-page-news-block/welcome-page-news-block.component";
import { WelcomePageEventsBlockContainerComponent } from "./containers/welcome-page-events-block-container/welcome-page-events-block-container.component";
import { WelcomePageOptionsBlockContainerComponent } from "./containers/welcome-page-options-block-container/welcome-page-options-block-container.component";
import { WelcomePageOptionsBlockComponent } from "./components/welcome-page-options-block/welcome-page-options-block.component";
import { WelcomePageEventsBlockComponent } from "./components/welcome-page-events-block/welcome-page-events-block.component";
import { NewsPageContentBlocksComponent } from "./components/news-page-content-blocks/news-page-content-blocks.component";
import { NewsPageContentBlockItemComponent } from "./components/news-page-content-block-item/news-page-content-block-item.component";
import { NewsPageAnnouncementsBlockContainerComponent } from "./containers/news-page-announcements-block-container/news-page-announcements-block-container.component";
import { NewsPageAnnouncementsBlockComponent } from "./components/news-page-announcements-block/news-page-announcements-block.component";
import { NewsPageGpraNewslettersBlockContainerComponent } from "./containers/news-page-gpra-newsletters-block-container/news-page-gpra-newsletters-block-container.component";
import { NewsPageGpraNewslettersBlockComponent } from "./components/news-page-gpra-newsletters-block/news-page-gpra-newsletters-block.component";
import { EventsPageContentBlocksComponent } from "./components/events-page-content-blocks/events-page-content-blocks.component";
import { EventsPageEventsBlockContainerComponent } from "./containers/events-page-events-block-container/events-page-events-block-container.component";
import { EventsPageEventsBlockComponent } from "./components/events-page-events-block/events-page-events-block.component";
import { PhotosListContainerComponent } from "./containers/photos-list-container/photos-list-container.component";
import { PhotosListComponent } from "./components/photos-list/photos-list.component";
import { PhotosListItemComponent } from "./components/photos-list-item/photos-list-item.component";
import { RemoveWrapperDirective } from "./directives/remove-wrapper.directive";
import { EmailUpdatesFormContainerComponent } from "./containers/email-updates-form-container/email-updates-form-container.component";
import { EmailUpdatesFormComponent } from "./components/email-updates-form/email-updates-form.component";
import { ExecutiveBoardPhotosListContainerComponent } from "./containers/executive-board-photos-list-container/executive-board-photos-list-container.component";
import { ExecutiveBoardPhotosListComponent } from "./components/executive-board-photos-list/executive-board-photos-list.component";
import { BoardOfDirectorsPhotosListContainerComponent } from "./containers/board-of-directors-photos-list-container/board-of-directors-photos-list-container.component";
import { BoardOfDirectorsPhotosListComponent } from "./components/board-of-directors-photos-list/board-of-directors-photos-list.component";
import { ContactFormContainerComponent } from "./containers/contact-form-container/contact-form-container.component";
import { ContactFormComponent } from "./components/contact-form/contact-form.component";
import { EventsPageClubsBlockComponent } from "./components/events-page-clubs-block/events-page-clubs-block.component";
import { EventsPageClubsBlockContainerComponent } from "./containers/events-page-clubs-block-container/events-page-clubs-block-container.component";
import { PageTextContainerComponent } from "./containers/page-text-container/page-text-container.component";
import { ImageThumbUrlPipe } from "./pipes/image-thumb-url.pipe";
import { ImageHdUrlPipe } from "./pipes/image-hd-url.pipe";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { SingleNewsContainerComponent } from "./containers/single-news-container/single-news-container.component";
import { SingleNewsComponent } from "./components/single-news/single-news.component";
import { EventDetailsPageContentBlocksComponent } from "./components/event-details-page-content-blocks/event-details-page-content-blocks.component";
import { EventDetailsPageEventBlockContainerComponent } from "./containers/event-details-page-event-block-container/event-details-page-event-block-container.component";
import { EventDetailsPageEventBlockComponent } from "./components/event-details-page-event-block/event-details-page-event-block.component";
import { SingleClubModalComponent } from "./components/single-club-modal/single-club-modal.component";
import { HasScrolledClassDirective } from "./directives/has-scrolled-class.directive";
import { NewsletterArchiveContainerComponent } from "./containers/newsletter-archive-container/newsletter-archive-container.component";
import { NewsletterArchiveComponent } from "./components/newsletter-archive/newsletter-archive.component";
import { SingleMediaModalComponent } from "./components/single-media-modal/single-media-modal.component";
import { GroupNewsByYearPipe } from "./pipes/group-news-by-year.pipe";
import { PaymentFormContainerComponent } from "./containers/payment-form-container/payment-form-container.component";
import { PaymentFormComponent } from "./components/payment-form/payment-form.component";

const declarationsAndExports = [
  HeaderNavigationMenuContainerComponent,
  HeaderNavigationMenuComponent,

  HeaderUtilitiesContainerComponent,
  HeaderUtilitiesComponent,

  HeaderComponent,
  FooterComponent,

  WelcomePageSliderContainerComponent,
  WelcomePageSliderComponent,

  WelcomePageContentBlocksComponent,
  WelcomePageContentBlockItemComponent,

  WelcomePageNewsBlockContainerComponent,
  WelcomePageNewsBlockComponent,

  WelcomePageEventsBlockContainerComponent,
  WelcomePageEventsBlockComponent,

  WelcomePageOptionsBlockContainerComponent,
  WelcomePageOptionsBlockComponent,

  NewsPageContentBlocksComponent,
  NewsPageContentBlockItemComponent,

  NewsPageAnnouncementsBlockContainerComponent,
  NewsPageAnnouncementsBlockComponent,

  NewsPageGpraNewslettersBlockContainerComponent,
  NewsPageGpraNewslettersBlockComponent,

  EventsPageContentBlocksComponent,

  EventsPageEventsBlockContainerComponent,
  EventsPageEventsBlockComponent,

  EventsPageClubsBlockContainerComponent,
  EventsPageClubsBlockComponent,

  PhotosListContainerComponent,
  PhotosListComponent,
  PhotosListItemComponent,

  RemoveWrapperDirective,

  EmailUpdatesFormContainerComponent,
  EmailUpdatesFormComponent,

  ExecutiveBoardPhotosListContainerComponent,
  ExecutiveBoardPhotosListComponent,

  BoardOfDirectorsPhotosListContainerComponent,
  BoardOfDirectorsPhotosListComponent,

  ContactFormContainerComponent,
  ContactFormComponent,

  PageTextContainerComponent,

  ImageThumbUrlPipe,
  ImageHdUrlPipe,

  PaginationComponent,

  SingleNewsContainerComponent,
  SingleNewsComponent,

  EventDetailsPageContentBlocksComponent,
  EventDetailsPageEventBlockContainerComponent,
  EventDetailsPageEventBlockComponent,

  SingleClubModalComponent,

  HasScrolledClassDirective,

  NewsletterArchiveContainerComponent,
  NewsletterArchiveComponent,

  SingleMediaModalComponent,

  GroupNewsByYearPipe,

  PaymentFormContainerComponent,
  PaymentFormComponent
];

@NgModule({
  entryComponents: [SingleClubModalComponent, SingleMediaModalComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxFormValidationModule,
    NgxMydataJsModule.forRoot({
      myDataBaseUrl: environment.myDataBaseUrl,
      siteBaseUrl: environment.siteBaseUrl
    }),
    NgxCommonModule,
    IonicModule
  ],
  declarations: [declarationsAndExports],
  exports: [
    NgxPaginationModule,
    declarationsAndExports,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxFormValidationModule,
    NgxMydataJsModule,
    NgxCommonModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class SharedModule {}
