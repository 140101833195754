import { handleActions } from "redux-actions";
import { IReduxAction } from "../../interfaces/redux-action";
import {
  GET_CART_SUCCESS,
  UPDATE_CART_SUCCESS,
  EMPTY_CART_SUCCESS
} from "../../constants/shopify-cart.constants";

const updateCartSuccess = (
  state: Record<string, any>,
  { payload }: IReduxAction<any>
) => {
  return Object.assign({}, state, payload.entities.cart);
};

const emptyCartSuccess = (
  state: Record<string, any>,
  { payload }: IReduxAction<any>
) => {
  return Object.assign({}, state, payload.entities.cart);
};

export const cartEntities = handleActions(
  {
    // [GET_CART_SUCCESS]: getCartSuccess
    [UPDATE_CART_SUCCESS]: updateCartSuccess,
    [EMPTY_CART_SUCCESS]: emptyCartSuccess
  },
  null
);
