import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-event-details-page-content-blocks",
  templateUrl: "./event-details-page-content-blocks.component.html",
  styleUrls: ["./event-details-page-content-blocks.component.scss"]
})
export class EventDetailsPageContentBlocksComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
