import { handleActions } from "redux-actions";
import { IReduxAction } from "../../interfaces/redux-action";
import {
  GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS,
  GET_ALL_PAGES_CONTENT_LIST_SUCCESS
} from "../../constants/page-content.constants";
import { IPagesContentList } from "../../../interfaces/page-content";
import { IReduxWelcomePageContent } from "../../interfaces/content/welcome-page-content";

const getAnnouncementIds = (payload: IPagesContentList): string[] => {
  const pathPrefix = "/news/announcements/";
  return payload.items
    .filter(item => item.path.startsWith(pathPrefix))
    .map(item => item.path);
};

const getEventIds = (payload: IPagesContentList): string[] => {
  const pathPrefix = "/events/events/";
  return payload.items
    .filter(item => item.path.startsWith(pathPrefix))
    .map(item => item.path);
};

const getAllPagesContentListSuccess = (
  state: IReduxWelcomePageContent,
  { payload }: IReduxAction<IPagesContentList>
) => {
  const announcementIds: string[] = getAnnouncementIds(payload).slice(0, 10);

  const eventIds: string[] = getEventIds(payload).slice(0, 10);

  const inProcess: boolean = payload.nextToken ? true : false;

  return {
    news: {
      inProcess,
      hasErrors: false,
      items: announcementIds
    },
    events: {
      inProcess,
      hasErrors: false,
      items: eventIds
    }
  };
};

export const welcomePageContent = handleActions(
  {
    [GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS]: getAllPagesContentListSuccess,
    [GET_ALL_PAGES_CONTENT_LIST_SUCCESS]: getAllPagesContentListSuccess
  },
  null
);
