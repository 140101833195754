import { Pipe, PipeTransform } from "@angular/core";
import { getImageHdUrl } from "../../utils/images";

@Pipe({
  name: "imageHdUrl"
})
export class ImageHdUrlPipe implements PipeTransform {
  transform(filePath: string): any {
    return getImageHdUrl(filePath);
  }
}
