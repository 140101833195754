import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { SubmitContentService } from "./submit-content.service";
import { IPaymentFormData } from "../interfaces/payment-form";

@Injectable({
  providedIn: "root"
})
export class PaymentService {
  constructor(private submitContentService: SubmitContentService) {}

  submit(data: IPaymentFormData) {
    // this.redux.dispatch(subscribeToNewsletterRequest(subscriptionInfo));
    return this.submitContentService
      .submit("/form/payment-form", {
        form_data: data
      })
      .pipe(
        tap(() => {
          // this.redux.dispatch(subscribeToNewsletterSuccess(subscriptionInfo));
        }),
        catchError((err: any) => {
          // this.redux.dispatch(subscribeToNewsletterError(err));
          return throwError(err);
        })
      );
  }
}
