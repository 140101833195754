import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { combineLatest, Observable, Subject } from "rxjs";
import { IPartialReduxEntities } from "../../../redux/interfaces/entities";
import { takeUntil, throttleTime } from "rxjs/operators";
import { getSchemaEntitiesObservable } from "../../../utils/normalizr";
import { newsSchema } from "../../../normalizr/schemas/news.schema";
import { NgRedux } from "@angular-redux/store";
import { IAppState } from "../../../redux/state/app-state.interface";
import { denormalize } from "normalizr";
import { INewsItem } from "../../../interfaces/news";

@Component({
  selector: "app-single-news-container",
  templateUrl: "./single-news-container.component.html",
  styleUrls: ["./single-news-container.component.scss"]
})
export class SingleNewsContainerComponent implements OnDestroy {
  item: INewsItem | undefined;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(route: ActivatedRoute, redux: NgRedux<IAppState>) {
    const entities$: Observable<
      IPartialReduxEntities
    > = getSchemaEntitiesObservable(redux, newsSchema);

    combineLatest(entities$, route.params)
      .pipe(
        takeUntil(this.destroy$),
        throttleTime(500, undefined, { leading: true, trailing: true })
      )
      .subscribe(([entities, params]) => {
        this.item = denormalize(params.path, newsSchema, entities);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
