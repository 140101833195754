import { Injectable } from "@angular/core";
import { ISubscribeToNewsletterInput } from "../interfaces/subscription";
import { NgRedux } from "@angular-redux/store";
import {
  subscribeToNewsletterError,
  subscribeToNewsletterRequest,
  subscribeToNewsletterSuccess
} from "../redux/actions/subscription.actions";
import { IAppState } from "../redux/state/app-state.interface";
import { tap, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { SubmitContentService } from "./submit-content.service";

@Injectable({
  providedIn: "root"
})
export class SubscriptionService {
  constructor(
    private redux: NgRedux<IAppState>,
    private submitContentService: SubmitContentService
  ) {}

  subscribeToNewsletter(subscriptionInfo: ISubscribeToNewsletterInput) {
    this.redux.dispatch(subscribeToNewsletterRequest(subscriptionInfo));
    return this.submitContentService
      .submit("/fanclub/subscribe", subscriptionInfo)
      .pipe(
        tap(() => {
          this.redux.dispatch(subscribeToNewsletterSuccess(subscriptionInfo));
        }),
        catchError((err: any) => {
          this.redux.dispatch(subscribeToNewsletterError(err));
          return throwError(err);
        })
      );
  }
}
