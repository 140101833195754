import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import {
  IFormError,
  markAllFormControlsAsTouched,
  setFormErrors
} from "@bubbleup/ngx-form-validation";
import { Platform } from "@ionic/angular";
import { IContactFormData } from "../../../interfaces/contact-form";

@Component({
  selector: "app-contact-form",
  templateUrl: "./contact-form.component.html",
  styleUrls: ["./contact-form.component.scss"]
})
export class ContactFormComponent implements OnChanges {
  group: FormGroup;

  @Input()
  inProcess: boolean = false;

  @Input() serverErrors: IFormError[] = [];

  // @todo: ildar. temporary disable recaptcha for ios
  isIos: boolean;

  @Input() recaptchaSiteKey: string = "";

  recaptchaSize: string;

  @Output() submit: EventEmitter<IContactFormData> = new EventEmitter<
    IContactFormData
  >();

  constructor(private formBuilder: FormBuilder, private platform: Platform) {
    this.isIos = this.platform.is("ios");

    this.group = this.formBuilder.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      subject: [""],
      message: [""]
    });

    if (!this.isIos) {
      this.group.setControl(
        "recaptcha",
        new FormControl("", Validators.required)
      );
    }

    this.recaptchaSize = this.platform.is("mobile") ? "compact" : "normal";
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.serverErrors) {
      const currentValue: IFormError[] = changes.serverErrors.currentValue;
      if (currentValue.length) {
        setFormErrors(this.group, currentValue);
      }
    }
  }

  validateAndSubmit() {
    if (this.inProcess) {
      return;
    }
    if (this.group.invalid) {
      return markAllFormControlsAsTouched(this.group);
    }

    this.submit.emit({
      firstName: this.group.value.firstName,
      lastName: this.group.value.lastName,
      email: this.group.value.email,
      subject: this.group.value.subject,
      message: this.group.value.message
    });
  }
}
