import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { ParentWithHeaderAndFooterPage } from "./pages/parent-with-header-and-footer/parent-with-header-and-footer.page";
import { GpraDescriptionPageModule } from "./pages/gpra-description/gpra-description.module";

const routes: Routes = [
  { path: "", redirectTo: "welcome", pathMatch: "full" },
  {
    path: "",
    component: ParentWithHeaderAndFooterPage,
    children: [
      // root pages
      {
        path: "welcome",
        loadChildren: "./pages/welcome/welcome.module#WelcomePageModule"
      },
      { path: "news", loadChildren: "./pages/news/news.module#NewsPageModule" },
      {
        path: "events",
        loadChildren: "./pages/events/events.module#EventsPageModule"
      },
      {
        path: "photos",
        loadChildren: "./pages/photos/photos.module#PhotosPageModule"
      },
      {
        path: "docs-forms",
        loadChildren: "./pages/docs-forms/docs-forms.module#DocsFormsPageModule"
      },
      {
        path: "email-updates",
        loadChildren:
          "./pages/email-updates/email-updates.module#EmailUpdatesPageModule"
      },
      {
        path: "directory",
        loadChildren: "./pages/directory/directory.module#DirectoryPageModule"
      },
      {
        path: "executive-board",
        loadChildren:
          "./pages/executive-board/executive-board.module#ExecutiveBoardPageModule"
      },
      {
        path: "board-of-directors",
        loadChildren:
          "./pages/board-of-directors/board-of-directors.module#BoardOfDirectorsPageModule"
      },
      {
        path: "contact",
        loadChildren: "./pages/contact/contact.module#ContactPageModule"
      },
      {
        path: "terms",
        loadChildren: "./pages/terms/terms.module#TermsPageModule"
      },
      {
        path: "privacy",
        loadChildren: "./pages/privacy/privacy.module#PrivacyPageModule"
      },
      {
        path: "email-updates-thank-you",
        loadChildren:
          "./pages/email-updates-thank-you/email-updates-thank-you.module#EmailUpdatesThankYouPageModule"
      },
      {
        path: "news-details",
        loadChildren:
          "./pages/news-details/news-details.module#NewsDetailsPageModule"
      },
      {
        path: "event-details",
        loadChildren:
          "./pages/event-details/event-details.module#EventDetailsPageModule"
      },
      {
        path: "newsletter-archive",
        loadChildren:
          "./pages/newsletter-archive/newsletter-archive.module#NewsletterArchivePageModule"
      },
      {
        path: "contact-thank-you",
        loadChildren:
          "./pages/contact-thank-you/contact-thank-you.module#ContactThankYouPageModule"
      },
      {
        path: "payment",
        loadChildren: "./pages/payment/payment.module#PaymentPageModule"
      },
      {
        path: "gpra-description",
        loadChildren:
          "./pages/gpra-description/gpra-description.module#GpraDescriptionPageModule"
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
