// init current user (begin)
export const INIT_CURRENT_USER_REQUEST = "INIT_CURRENT_USER_REQUEST";
export const INIT_CURRENT_USER_SUCCESS = "INIT_CURRENT_USER_SUCCESS";
export const INIT_CURRENT_USER_ERROR = "INIT_CURRENT_USER_ERROR";
// init current user (end)

// sign in (begin)
export const SIGN_IN_REQUEST = "SIGN_IN_REQUEST";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
// sign in (end)

// sign up (begin)
export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_ERROR = "SIGN_UP_ERROR";
// sign up (end)

// confirm sign up (begin)
export const CONFIRM_SIGN_UP_REQUEST = "CONFIRM_SIGN_UP_REQUEST";
export const CONFIRM_SIGN_UP_SUCCESS = "CONFIRM_SIGN_UP_SUCCESS";
export const CONFIRM_SIGN_UP_ERROR = "CONFIRM_SIGN_UP_ERROR";
// confirm sign up (end)

// resend sign up (begin)
export const RESEND_SIGN_UP_REQUEST = "RESEND_SIGN_UP_REQUEST";
export const RESEND_SIGN_UP_SUCCESS = "RESEND_SIGN_UP_SUCCESS";
export const RESEND_SIGN_UP_ERROR = "RESEND_SIGN_UP_ERROR";
// resend sign up (end)

// forgot password (begin)
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
// forgot password (end)

// forgot password submit (begin)
export const FORGOT_PASSWORD_SUBMIT_REQUEST = "FORGOT_PASSWORD_SUBMIT_REQUEST";
export const FORGOT_PASSWORD_SUBMIT_SUCCESS = "FORGOT_PASSWORD_SUBMIT_SUCCESS";
export const FORGOT_PASSWORD_SUBMIT_ERROR = "FORGOT_PASSWORD_SUBMIT_ERROR";
// forgot password submit (end)

// logout (begin)
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
// logout (end)
