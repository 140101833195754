import { createAction } from "redux-actions";
import {
  GET_ALL_PAGES_CONTENT_LIST_ERROR,
  GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS,
  GET_ALL_PAGES_CONTENT_LIST_REQUEST,
  GET_ALL_PAGES_CONTENT_LIST_SUCCESS
} from "../constants/page-content.constants";
import { IPagesContentList } from "../../interfaces/page-content";

// Get all pages content list (begin)
export const getAllPagesContentListRequest = () =>
  createAction(GET_ALL_PAGES_CONTENT_LIST_REQUEST)();

export const getAllPagesContentListSuccess = (
  pagesContentList: IPagesContentList
) => createAction(GET_ALL_PAGES_CONTENT_LIST_SUCCESS)(pagesContentList);

export const getAllPagesContentListFirstChunkSuccess = (
  pagesContentList: IPagesContentList
) =>
  createAction(GET_ALL_PAGES_CONTENT_LIST_FIRST_CHUNK_SUCCESS)(
    pagesContentList
  );

export const getAllPagesContentListError = (err: any) =>
  createAction(GET_ALL_PAGES_CONTENT_LIST_ERROR)(err);
// Get all pages content list (end)
