import { Component, OnDestroy } from "@angular/core";
import { combineLatest, Observable, Subject } from "rxjs";
import { IReduxListContent } from "../../../redux/interfaces/content/list-content";
import { IAppState } from "../../../redux/state/app-state.interface";
import { getSchemaEntitiesObservable } from "../../../utils/normalizr";
import { takeUntil, throttleTime } from "rxjs/operators";
import { NgRedux } from "@angular-redux/store";
import { INewsItem } from "../../../interfaces/news";
import { IPartialReduxEntities } from "../../../redux/interfaces/entities";
import { denormalize } from "normalizr";
import { compact as _compact } from "lodash";
import { newsSchema } from "../../../normalizr/schemas/news.schema";

@Component({
  selector: "app-events-page-clubs-block-container",
  templateUrl: "./events-page-clubs-block-container.component.html",
  styleUrls: ["./events-page-clubs-block-container.component.scss"]
})
export class EventsPageClubsBlockContainerComponent implements OnDestroy {
  items: INewsItem[] = [];
  inProcess: boolean = true;
  hasErrors: boolean = false;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(redux: NgRedux<IAppState>) {
    const eventsContent$: Observable<IReduxListContent> = redux.select([
      "content",
      "eventsPage",
      "clubs"
    ]);
    const entities$: Observable<
      IPartialReduxEntities
    > = getSchemaEntitiesObservable(redux, newsSchema);

    combineLatest(entities$, eventsContent$)
      .pipe(
        takeUntil(this.destroy$),
        throttleTime(500, undefined, { leading: true, trailing: true })
      )
      .subscribe(([entities, eventsContent]) => {
        this.items = _compact(
          denormalize(eventsContent.items, [newsSchema], entities)
        );
        this.inProcess = eventsContent.inProcess;
        this.hasErrors = eventsContent.hasErrors;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
