import { Component, Input } from "@angular/core";
import { IMediaItem } from "../../../interfaces/media";
import { SingleMediaModalComponent } from "../single-media-modal/single-media-modal.component";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-executive-board-photos-list",
  templateUrl: "./executive-board-photos-list.component.html",
  styleUrls: ["./executive-board-photos-list.component.scss"]
})
export class ExecutiveBoardPhotosListComponent {
  @Input() items: IMediaItem[] = [];
  @Input() inProcess: boolean = true;
  @Input() hasErrors: boolean = false;

  constructor(private modalController: ModalController) {}

  trackByMediaItem(index: number, item: IMediaItem): string {
    return item.filePath;
  }

  async openModal(item: IMediaItem): Promise<void> {
    const modal = await this.modalController.create({
      component: SingleMediaModalComponent,
      componentProps: {
        item
      }
    });
    modal.present();
  }
}
